import {
  Avatar,
  AvatarFallback,
  Button,
  Command,
  CommandGroup,
  CommandItem,
  CommandSeparator,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Textarea,
} from "@/components/Elements";
import { cn, usernameToColor } from "@/utils/style";
import { createDOMRange, createRectsFromDOMRange } from "@lexical/selection";
import dayjs from "dayjs";
import { $getSelection, $isRangeSelection, LexicalEditor } from "lexical";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TbCheck, TbDots } from "react-icons/tb";
import { CommentsComposer } from ".";
import { Comment, Thread } from "../../commenting";

interface CommentWithThread extends Comment {
  isThread: boolean;
}

function CommentThread({
  editor,
  cancelAddComment,
  submitAddComment,
  submitDeleteComment,
  submitEditComment,
  userFullName,
  thread,
  documentHash,
  anchorElem,
  className,
  isFloating,
}: {
  anchorElem: HTMLElement;
  cancelAddComment: () => void;
  editor: LexicalEditor;
  submitAddComment: (
    commentOrThread: Comment | Thread,
    isInlineComment: boolean,
    thread?: Thread
  ) => void;
  submitDeleteComment: (
    commentOrThread: Comment | Thread,
    thread?: Thread,
    shouldDeleteThread?: boolean
  ) => void;
  submitEditComment: (id: number, content: string, section_id: string) => void;
  userFullName: string;
  thread: Thread;
  documentHash: string;
  className?: string;
  isFloating?: boolean;
}) {
  const boxRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const selectionState = useMemo(
    () => ({
      container: document.createElement("div"),
      elements: [],
    }),
    []
  );
  const [editingCommentId, setEditingCommentId] = useState<number | null>(null);
  const [editingContent, setEditingContent] = useState<string>("");
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState<Comment | null>(null);
  const [newCommentAdded, setNewCommentAdded] = useState(false);

  const handleEditComment = (comment: Comment) => {
    setEditingCommentId(comment.server_id);
    setEditingContent(comment.content);
  };

  const handleSaveEdit = (comment: Comment) => {
    submitEditComment(comment.server_id, editingContent, comment.id);
    setEditingCommentId(null);
    setEditingContent("");
  };

  const handleDeleteComment = (comment: Comment, index: number) => {
    const isThread = index === 0;
    setCommentToDelete({ ...comment, isThread } as CommentWithThread);
    setIsConfirmDialogOpen(true);
  };

  const confirmDelete = () => {
    if (commentToDelete) {
      submitDeleteComment(
        commentToDelete,
        thread,
        (commentToDelete as CommentWithThread).isThread
      );
      setCommentToDelete(null);
      setIsConfirmDialogOpen(false);
    }
  };

  const updateLocation = useCallback(() => {
    if (!isFloating) return;

    editor.getEditorState().read(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        const anchor = selection.anchor;
        const focus = selection.focus;
        const range = createDOMRange(
          editor,
          anchor.getNode(),
          anchor.offset,
          focus.getNode(),
          focus.offset
        );
        const boxElem = boxRef.current;
        if (range !== null && boxElem !== null) {
          const { left, bottom, width } = range.getBoundingClientRect();
          const selectionRects = createRectsFromDOMRange(editor, range);
          let correctedLeft =
            selectionRects.length === 1 ? left + width / 2 - 125 : left - 125;
          if (correctedLeft < 10) {
            correctedLeft = 10;
          }
          if (correctedLeft + boxElem.offsetWidth > window.innerWidth) {
            correctedLeft = window.innerWidth - boxElem.offsetWidth - 10;
          }

          // Get the height of the mark node
          const markNode = anchor.getNode().getParent()?.getKey();
          const markDomNode = editor.getElementByKey(markNode as string);
          const markDomNodeRect = markDomNode?.getBoundingClientRect();

          let correctedTop = markDomNodeRect?.y + markDomNodeRect?.height;
          if (correctedTop + boxElem.offsetHeight > window.innerHeight) {
            correctedTop = window.innerHeight - boxElem.offsetHeight - 10;
          }
          boxElem.style.left = `${correctedLeft}px`;
          boxElem.style.top = `${correctedTop}px`;
          const selectionRectsLength = selectionRects.length;
          const { container } = selectionState;
          const elements: Array<HTMLSpanElement> = selectionState.elements;
          const elementsLength = elements.length;

          for (let i = 0; i < selectionRectsLength; i++) {
            const selectionRect = selectionRects[i];
            let elem: HTMLSpanElement = elements[i];
            if (elem === undefined) {
              elem = document.createElement("span");
              elements[i] = elem;
              container.appendChild(elem);
            }
            const color = "255, 212, 0";
            const style = `position:absolute;top:${selectionRect.top}px;left:${selectionRect.left}px;height:${selectionRect.height}px;width:${selectionRect.width}px;background-color:rgba(${color}, 0.3);pointer-events:none;z-index:5;`;
            elem.style.cssText = style;
          }
          for (let i = elementsLength - 1; i >= selectionRectsLength; i--) {
            const elem = elements[i];
            container.removeChild(elem);
            elements.pop();
          }
        }
      } else {
        const boxElem = boxRef.current;
        if (boxElem) {
          boxElem.style.opacity = "0";
          boxElem.style.transform = "translate(-10000px, -10000px)";
        }
      }
    });
  }, [editor, anchorElem, selectionState]);

  useEffect(() => {
    if (isFloating && anchorElem) {
      const scrollerElem = anchorElem.parentElement;

      const update = () => {
        editor.getEditorState().read(() => {
          updateLocation();
        });
      };

      window.addEventListener("resize", update);
      if (scrollerElem) {
        scrollerElem.addEventListener("scroll", update);
      }

      return () => {
        window.removeEventListener("resize", update);
        if (scrollerElem) {
          scrollerElem.removeEventListener("scroll", update);
        }
      };
    }
  }, [editor, updateLocation, anchorElem, isFloating]);

  useEffect(() => {
    updateLocation();
  }, [thread, isFloating]);

  const handleAddComment = async (
    commentOrThread: Comment | Thread,
    isInlineComment: boolean,
    thread?: Thread
  ) => {
    await submitAddComment(commentOrThread, isInlineComment, thread);
    setNewCommentAdded(true);
  };

  useEffect(() => {
    if (newCommentAdded) {
      const scrollElem = scrollRef.current;
      if (scrollElem) {
        scrollElem.scrollTop = scrollElem.scrollHeight;
      }
    }
  }, [thread?.comments, newCommentAdded]);

  return (
    (thread?.comments.length > 0 && (
      <div
        className={cn(
          isFloating
            ? "absolute w-[360px] max-w-[360px] z-[50] min-w-fit mt-1 top-0 left-0 dark:divide-zinc-600 rounded-md backdrop-blur-lg bg-white/90 dark:bg-zinc-900/50 shadow-lg ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5 focus:outline-none"
            : "flex flex-col w-[360px] max-w-[360px] z-[50] min-w-fit mt-1 dark:divide-zinc-600 rounded-md backdrop-blur-lg bg-white/90 dark:bg-zinc-900/50 shadow-lg ring-1 ring-zinc-900 dark:ring-1 dark:ring-zinc-50 dark:ring-opacity-5 ring-opacity-5 focus:outline-none",
          className
        )}
        ref={boxRef}
      >
        <div className="flex flex-col h-full">
          <div
            className="flex-grow overflow-y-auto max-h-[400px]"
            ref={scrollRef}
          >
            {thread?.comments
              ?.sort(
                (a, b) =>
                  new Date(a.timeStamp).getTime() -
                  new Date(b.timeStamp).getTime()
              )
              .map((comment, index) => (
                <div className="flex flex-col space-y-2 border-b border-b-zinc-100 dark:border-b-zinc-800 py-2 last:border-none">
                  <div
                    key={comment.id}
                    className="rounded-md flex space-x-2 items-center px-3 justify-between w-full"
                  >
                    <div className="flex items-center space-x-2">
                      <Avatar className="w-5 h-5">
                        <AvatarFallback
                          className={cn(
                            "text-white font-semibold text-4xs",
                            usernameToColor(comment.author)
                          )}
                        >
                          {comment.author
                            ?.split(" ")
                            .map((name) => name[0])
                            .slice(0, 2)
                            .join("")}
                        </AvatarFallback>
                      </Avatar>
                      <p className="text-zinc-900 dark:text-zinc-100 text-xs font-medium">
                        {comment.author}
                      </p>
                      <p className="text-zinc-600 dark:text-zinc-400 text-xs lowercase">
                        {dayjs(comment.timeStamp).fromNow()}
                      </p>
                    </div>
                    <div className="flex">
                      {index === 0 && (
                        <Button
                          variant="buttonIcon"
                          className="flex-shrink-0"
                          size="sm"
                          onClick={() =>
                            submitDeleteComment(thread, thread, true)
                          }
                          buttonIcon={<TbCheck />}
                          tooltipContent="Resolve thread"
                        ></Button>
                      )}
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant="buttonIcon"
                            size="sm"
                            buttonIcon={<TbDots />}
                            tooltipContent="Comment options"
                          ></Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <Command>
                            <CommandGroup>
                              {comment.author === userFullName && (
                                <CommandItem
                                  onSelect={() => handleEditComment(comment)}
                                >
                                  Edit comment
                                </CommandItem>
                              )}
                              <CommandItem
                                onSelect={() =>
                                  submitDeleteComment(comment, thread, true)
                                }
                              >
                                Resolve thread
                              </CommandItem>
                              {comment.author === userFullName && (
                                <>
                                  <CommandSeparator className="my-1" />

                                  <CommandItem
                                    onSelect={() =>
                                      handleDeleteComment(comment, index)
                                    }
                                    className="text-red-500 dark:text-red-400"
                                  >
                                    {index === 0
                                      ? "Delete thread"
                                      : "Delete comment"}
                                  </CommandItem>
                                </>
                              )}
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>
                  {editingCommentId === comment.server_id ? (
                    <div className="flex flex-col space-y-2 px-3">
                      <Textarea
                        autoFocus
                        className="text-zinc-900 px-3 text-xs dark:text-white"
                        value={editingContent}
                        onChange={(e) => setEditingContent(e.target.value)}
                        ref={(input) => {
                          if (input) {
                            const length = input.value.length;
                            input.setSelectionRange(length, length);
                          }
                        }}
                      />
                      <Button
                        onClick={() => handleSaveEdit(comment)}
                        className="flex-shrink-0"
                        variant="primaryBlur"
                        size="2xs"
                      >
                        Save
                      </Button>
                    </div>
                  ) : (
                    <p className="text-zinc-900 px-3 text-xs dark:text-white break-words max-w-[360px]">
                      {comment.content.split("\n").map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </p>
                  )}
                </div>
              ))}
          </div>
          <div
            className={cn(
              "mt-auto px-1",
              thread?.comments?.length > 0 &&
                "border-t border-t-zinc-100 dark:border-t-zinc-800"
            )}
          >
            <CommentsComposer
              userFullName={userFullName}
              submitAddComment={handleAddComment}
              thread={thread}
              placeholder="Reply to comment..."
              documentHash={documentHash}
            />
          </div>
          <Dialog
            open={isConfirmDialogOpen}
            onOpenChange={setIsConfirmDialogOpen}
          >
            <DialogContent>
              <DialogHeader className="pb-0 flex items-center justify-between">
                <DialogTitle>
                  {commentToDelete &&
                  (commentToDelete as CommentWithThread).isThread
                    ? "Delete this thread?"
                    : "Delete this comment?"}
                </DialogTitle>
              </DialogHeader>
              <DialogDescription className="px-4">
                {commentToDelete &&
                (commentToDelete as CommentWithThread).isThread
                  ? "Are you sure you want to delete this thread?"
                  : "Are you sure you want to delete this comment?"}
              </DialogDescription>
              <DialogFooter className="px-4 pb-4">
                <Button
                  onClick={() => setIsConfirmDialogOpen(false)}
                  variant="outlineBlur"
                >
                  Cancel
                </Button>
                <Button onClick={confirmDelete} variant="destructiveBlur">
                  Delete
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    )) || <></>
  );
}

export default CommentThread;
