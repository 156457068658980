import { TooltipPortal } from "@radix-ui/react-tooltip";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Fragment, useState } from "react";
import { TbCheck, TbInfoCircle, TbMinus } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Tag,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/Elements";
import { ContentLayout } from "../../../components/Layout";
import { cn } from "../../../utils/style";
import { useSubscription } from "../../auth/api/getSubscription";
import { getPlanNickname } from "../../subscription/utils/getPlanNickname";
import { sections } from "../../subscription/utils/sections";
import { tiers } from "../../subscription/utils/tiers";
import { ConfirmPlanChangeDialog } from "../components/ConfirmPlanChangeDialog";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY as string);

const PricingToggleGroup = () => {
  const [pricingPeriod, setPricingPeriod] = useState("monthly");

  const getButtonClasses = (value) => {
    return `hover:bg-zinc-300 text-zinc-900 ${
      pricingPeriod === value ? "bg-zinc-600 text-white" : "bg-white"
    } flex h-[35px] w-[35px] items-center justify-center text-base leading-4 first:rounded-l last:rounded-r focus:z-10 focus:shadow-outline focus:outline-none`;
  };

  return (
    <div
      className="inline-flex rounded shadow space-x-px"
      aria-label="Billing cycle"
    >
      <button
        className={getButtonClasses("monthly")}
        onClick={() => setPricingPeriod("monthly")}
        aria-label="Monthly pricing"
      >
        Monthly
      </button>
      <button
        className={getButtonClasses("yearly")}
        onClick={() => setPricingPeriod("yearly")}
        aria-label="Yearly pricing"
      >
        Yearly
      </button>
    </div>
  );
};

export const Plans = () => {
  const { data: subscriptionData } = useSubscription({});
  const currentPlanName = getPlanNickname(
    subscriptionData?.plan || "freeTrial"
  );
  const hasAddOn = subscriptionData?.add_on ? true : false;
  const navigate = useNavigate();

  const displayedTiers = tiers.filter((tier) => tier.display);
  const isSubscriptionInactive = subscriptionData?.status === "inactive";
  const isSubscriptionCancelled =
    subscriptionData?.status === "canceled" ||
    subscriptionData?.status === "active_canceled";
  const [pricingPeriod, setPricingPeriod] = useState(
    subscriptionData?.billing_cycle === "year" ? "yearly" : "monthly"
  );

  const handleManagePlanClick = () => {
    navigate("/app/settings/subscription");
  };

  return (
    <ContentLayout>
      <div className="flex flex-col items-start h-screen pt-5 px-8 w-full space-y-4 overflow-y-scroll">
        <div>
          <h2 className="text-xl dark:text-white font-medium">Plans</h2>
          <div className="isolate w-full h-full max-w-[900px]">
            <div className="relative -mx-8">
              {displayedTiers.some((tier) => currentPlanName === tier.name) ? (
                <div className="absolute inset-x-0 inset-y-0 -z-10 flex">
                  <div
                    className="flex w-1/4 px-4"
                    aria-hidden="true"
                    style={{
                      marginLeft: `${
                        (displayedTiers.findIndex(
                          (tier) => currentPlanName === tier.name
                        ) +
                          1) *
                        25
                      }%`,
                    }}
                  >
                    <div className="w-full rounded-t-xl border-x border-t border-zinc-900/10 bg-zinc-400/5" />
                  </div>
                </div>
              ) : (
                <div className="absolute inset-x-0 inset-y-0 -z-10 flex">
                  <div
                    className="flex w-1/4 px-4 relative" // Added 'relative' here to make it the positioning context for the Tag
                    aria-hidden="true"
                    style={{
                      marginLeft: `${
                        (displayedTiers.findIndex(
                          (tier) => tier.name === "Basic" // Assuming "Basic" is the name of the most popular plan
                        ) +
                          1) *
                        25
                      }%`,
                    }}
                  >
                    <div className="w-full rounded-t-xl border-x border-t border-emerald-600/30 bg-upgrade-gradient-linear" />
                    <Tag
                      variant="small"
                      className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-full mt-3 whitespace-nowrap bg-emerald-50 z-10"
                      textClassName="font-medium"
                      color="emerald"
                    >
                      Most popular
                    </Tag>
                  </div>
                </div>
              )}
              <table className="w-full border-separate border-spacing-x-8 text-left table-fixed">
                <caption className="sr-only">Pricing plan comparison</caption>
                <colgroup>
                  <col className="w-1/4" />
                  <col className="w-1/4" />
                  <col className="w-1/4" />
                  <col className="w-1/4" />
                </colgroup>
                <thead>
                  <tr>
                    <td>
                      {isSubscriptionInactive && (
                        <div
                          className="flex bg-zinc-100 w-fit p-1 rounded-md mt-6 dark:bg-zinc-800"
                          aria-label="Billing cycle"
                        >
                          <Button
                            variant={
                              pricingPeriod === "monthly"
                                ? "outlineBlur"
                                : "text"
                            }
                            onClick={() => setPricingPeriod("monthly")}
                            aria-label="Monthly pricing"
                            size="2xs"
                          >
                            Monthly
                          </Button>
                          <Button
                            variant={
                              pricingPeriod === "yearly"
                                ? "outlineBlur"
                                : "text"
                            }
                            onClick={() => setPricingPeriod("yearly")}
                            aria-label="Yearly pricing"
                            size="2xs"
                          >
                            Yearly
                          </Button>
                        </div>
                      )}
                    </td>
                    {displayedTiers.map((tier) => (
                      <th
                        key={tier.id}
                        scope="col"
                        className="text-left pt-4 px-6"
                      >
                        <div className="flex items-center justify-between w-full">
                          <p className="text-lg text-zinc-900 dark:text-white font-semibold w-fit">
                            {tier.name}
                          </p>
                          {currentPlanName === tier.name && (
                            <Tag
                              variant="small"
                              className="whitespace-nowrap bg-zinc-50 z-10 h-6"
                              textClassName="font-medium"
                              color="zinc"
                            >
                              Current plan
                            </Tag>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" className="text-left align-middle px-6">
                      <span className="sr-only">Price</span>
                    </th>
                    {displayedTiers.map((tier) => (
                      <td
                        key={tier.id}
                        className="text-left align-middle pt-2 px-6"
                      >
                        <div className="items-baseline gap-x-1 text-zinc-900 dark:text-white mb-4">
                          <span className="text-2xl font-bold">
                            <span className="text-2xl font-semibold">
                              {pricingPeriod === "monthly"
                                ? tier.monthly.priceDisplay
                                : tier.yearly.priceDisplay}
                            </span>

                            <span className="text-base font-semibold leading-6 text-zinc-900 dark:text-white">
                              {pricingPeriod === "monthly" ? "/mo" : "/yr"}
                            </span>
                          </span>
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      className="text-left align-middle pt-2 px-6"
                    >
                      <span className="sr-only">Actions</span>
                    </th>
                    {displayedTiers.map((tier) => {
                      // Find the current tier's order
                      const currentTierOrder =
                        tiers.find((t) => t.name === currentPlanName)?.order ||
                        0;
                      // Compare the current tier's order with the iterated tier's order
                      let actionElement;
                      if (isSubscriptionCancelled) {
                        // Render resubscribe option for all tiers if the subscription is cancelled
                        actionElement = (
                          <Elements stripe={stripePromise}>
                            <ConfirmPlanChangeDialog
                              planDetails={{
                                name: tier.name,
                                stripeName:
                                  pricingPeriod === "monthly"
                                    ? tier.monthly.stripeName
                                    : tier.yearly.stripeName,
                                title: `${tier.name} Plan`,
                                price:
                                  pricingPeriod === "monthly"
                                    ? tier.monthly.price
                                    : tier.yearly.price,
                              }}
                              status={subscriptionData?.status}
                              hasAddOn={hasAddOn}
                              tiers={tiers}
                              pricingPeriod={pricingPeriod}
                              shouldResubscribe={true}
                              actionType="resubscribe"
                            />
                          </Elements>
                        );
                      } else if (tier.order === currentTierOrder) {
                        actionElement = (
                          <div className="flex-col space-y-2">
                            <Button
                              variant="outlineBlur"
                              className="w-full"
                              onClick={handleManagePlanClick}
                            >
                              Manage plan
                            </Button>
                            <Elements stripe={stripePromise}>
                              <ConfirmPlanChangeDialog
                                planDetails={{
                                  name: tier.name,
                                  stripeName:
                                    pricingPeriod === "monthly"
                                      ? tier.yearly.stripeName
                                      : tier.monthly.stripeName,
                                  title: `${tier.name} Plan`,
                                  price:
                                    pricingPeriod === "monthly"
                                      ? tier.yearly.price
                                      : tier.monthly.price,
                                }}
                                status={subscriptionData?.status}
                                hasAddOn={hasAddOn}
                                tiers={tiers}
                                pricingPeriod={pricingPeriod}
                                actionType="change"
                              />
                            </Elements>
                          </div>
                        );
                      } else if (tier.order < currentTierOrder) {
                        actionElement = (
                          <Elements stripe={stripePromise}>
                            <ConfirmPlanChangeDialog
                              planDetails={{
                                name: tier.name,
                                stripeName:
                                  pricingPeriod === "monthly"
                                    ? tier.monthly.stripeName
                                    : tier.yearly.stripeName,
                                title: `${tier.name} Plan`,
                                price:
                                  pricingPeriod === "monthly"
                                    ? tier.monthly.price
                                    : tier.yearly.price,
                                documents: tier.documents,
                                users: tier.users,
                                searchQueries: tier.searchQueries,
                              }}
                              status={subscriptionData?.status}
                              hasAddOn={hasAddOn}
                              tiers={tiers}
                              pricingPeriod={pricingPeriod}
                              actionType="downgrade"
                            />
                          </Elements>
                        );
                      } else {
                        actionElement = (
                          <Elements stripe={stripePromise}>
                            <ConfirmPlanChangeDialog
                              planDetails={{
                                name: tier.name,
                                stripeName:
                                  pricingPeriod === "monthly"
                                    ? tier.monthly.stripeName
                                    : tier.yearly.stripeName,
                                title: `${tier.name} Plan`,
                                price:
                                  pricingPeriod === "monthly"
                                    ? tier.monthly.price
                                    : tier.yearly.price,
                                documents: tier.documents,
                                users: tier.users,
                                searchQueries: tier.searchQueries,
                              }}
                              status={subscriptionData?.status}
                              hasAddOn={hasAddOn}
                              tiers={tiers}
                              pricingPeriod={pricingPeriod}
                              actionType="upgrade"
                            />
                          </Elements>
                        );
                      }
                      return (
                        <td key={tier.id} className="text-left align-top px-6">
                          {actionElement}
                        </td>
                      );
                    })}
                  </tr>
                  {sections.map((section, sectionIdx) => (
                    <Fragment key={section.name}>
                      <tr>
                        <th
                          scope="colgroup"
                          colSpan={4}
                          className={cn(
                            sectionIdx === 0 ? "pt-4" : "pt-6",
                            "pb-2 text-medium font-semibold leading-6 text-zinc-900 dark:text-white"
                          )}
                        >
                          {section.name}
                          <div className="absolute inset-x-8 mt-2 h-px bg-zinc-900/10 dark:bg-white/10" />
                        </th>
                      </tr>
                      {section.features.map((feature) => (
                        <tr key={feature.name}>
                          <th
                            scope="row"
                            className="py-2.5 text-sm font-normal leading-6 text-zinc-900 dark:text-white whitespace-nowrap"
                          >
                            <div className="flex items-center">
                              {feature.name}
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger asChild>
                                    <span className="inline-flex ml-2">
                                      <TbInfoCircle aria-label="More info" />
                                    </span>
                                  </TooltipTrigger>
                                  <TooltipPortal>
                                    <TooltipContent className="z-[10000]">
                                      {feature.tooltip}
                                    </TooltipContent>
                                  </TooltipPortal>
                                </Tooltip>
                              </TooltipProvider>
                            </div>
                            <div className="absolute inset-x-8 mt-2.5 h-px bg-zinc-900/5 dark:bg-white/5" />
                          </th>
                          {displayedTiers.map((tier) => (
                            <td
                              key={tier.id}
                              className="text-left align-middle px-6 py-2.5 xl:px-8 whitespace-nowrap"
                            >
                              {typeof feature.tiers[tier.name] === "string" ? (
                                <div className="text-left text-sm leading-6 text-zinc-900 dark:text-white">
                                  {feature.tiers[tier.name]}
                                </div>
                              ) : (
                                <>
                                  {feature.tiers[tier.name] === true ? (
                                    <TbCheck
                                      className="h-5 w-5 text-emerald-600"
                                      aria-hidden="true"
                                      style={{
                                        strokeWidth: 2.5,
                                      }}
                                    />
                                  ) : (
                                    <TbMinus
                                      className="h-5 w-5 text-zinc-400 dark:text-white"
                                      aria-hidden="true"
                                    />
                                  )}

                                  <span className="sr-only">
                                    {feature.tiers[tier.name] === true
                                      ? "Included"
                                      : "Not included"}{" "}
                                    in {tier.name}
                                  </span>
                                </>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
