import { MainLayout } from "@/components/Layout";
import { Usage } from "@/features/settings/routes/Usage";
import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { SettingsLayout } from "../components/Layout/SettingsLayout";
import { useTrackPage } from "../features/analytics/api/trackPage";

import { AiToolsRoutes } from "@/features/ai-tools";
import { AiDocumentWizardForm } from "@/features/ai/components/AiDocumentWizardForm";
import { ResetPassword } from "@/features/auth/routes/ResetPassword";
import { DocumentsRoutes } from "@/features/documents";
import { ExtensionRoutes } from "@/features/extension/routes";
import { FoldersRoutes } from "@/features/folders/routes";
import { AiTools, Help, SeoTools, Updates } from "@/features/misc";
import { SeoAnalyticsRoutes } from "@/features/seo-analytics";
import { Api } from "@/features/settings/routes";
import { Integrations } from "@/features/settings/routes/Integrations";
import { TemplatesRoutes } from "@/features/templates";
import { Profile } from "@/features/users";
import { VerifyEmail } from "@/features/verification/routes/VerifyEmail";
import { useAuth } from "@/lib/auth";
import { Users } from "lucide-react";
import { Account } from "../features/settings/routes/Account";
import { Billing } from "../features/settings/routes/Billing";
import { Plans } from "../features/settings/routes/Plans";
import { Subscription } from "../features/settings/routes/Subscription";
import { Team } from "../features/settings/routes/Team";
import { NavigateWithQuery } from "./public";

const App = () => {
  const location = useLocation();
  const trackPageMutation = useTrackPage();
  const { user } = useAuth();

  if (
    user &&
    !user.verified &&
    window.location.pathname !== "/app/verify-email" &&
    !window.location.pathname.startsWith("/app/verification/")
  ) {
    return <Navigate to="/app/verify-email" replace />;
  }

  useEffect(() => {
    trackPageMutation.mutate({
      pageName: location.pathname,
      properties: JSON.stringify({ beta: true }),
    });
  }, [location.pathname]);

  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

const Settings = () => {
  const location = useLocation();
  const trackPageMutation = useTrackPage();

  useEffect(() => {
    trackPageMutation.mutate({
      pageName: location.pathname,
      properties: JSON.stringify({ beta: true }),
    });
  }, [location.pathname]);

  return (
    <SettingsLayout>
      <Outlet />
    </SettingsLayout>
  );
};

export const protectedRoutes = [
  {
    path: "/app/*",
    element: <App />,
    children: [
      { path: "documents/*", element: <DocumentsRoutes /> },
      { path: "templates/*", element: <TemplatesRoutes /> },
      { path: "ai-tools/*", element: <AiToolsRoutes /> },
      { path: "folders/*", element: <FoldersRoutes /> },
      { path: "extension/*", element: <ExtensionRoutes /> },
      { path: "seo-analytics/*", element: <SeoAnalyticsRoutes /> },
      { path: "ai-tools", element: <AiTools /> },
      { path: "analytics", element: <SeoTools /> },
      { path: "help", element: <Help /> },
      { path: "updates", element: <Updates /> },
      { path: "users", element: <Users /> },
      { path: "profile", element: <Profile /> },
      { path: "dashboard", element: <DocumentsRoutes /> },
      { path: "", element: <Navigate to="/app/documents" replace /> },
      { path: "*", element: <Navigate to="/app/documents" replace /> },
      {
        path: "verify-email",
        element: <VerifyEmail />,
      },
      {
        path: "resetpassword",
        element: <NavigateWithQuery to="/resetpassword" />,
      },
    ],
  },
  { path: "*", element: <Navigate to="/app/documents" replace /> },
  { path: "/app/documents", element: <Navigate to="/app/documents" replace /> },
  { path: "resetpassword", element: <ResetPassword /> },
  {
    path: "/app/settings/*",
    element: <Settings />,
    children: [
      { path: "subscription", element: <Subscription /> },
      { path: "account", element: <Account /> },
      { path: "team", element: <Team /> },
      { path: "plans", element: <Plans /> },
      { path: "billing", element: <Billing /> },
      { path: "usage", element: <Usage /> },
      { path: "integrations", element: <Integrations /> },
      { path: "api", element: <Api /> },
      {
        path: "/app/settings/*",
        element: <Navigate to="/app/settings/account" replace />,
      },
      {
        path: "*",
        element: <Navigate to="/app/settings/account" replace />,
      },
    ],
  },
  { path: "/app/wizard", element: <AiDocumentWizardForm /> },
];
