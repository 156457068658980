import outline_group_dark from "@/assets/outline_group_dark.svg";
import outline_group_light from "@/assets/outline_group_light.svg";
import {
  ArticleCard,
  Button,
  Combobox,
  DialogCloseButton,
  DialogModal,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
  Input,
  Popover,
  PopoverContent,
  PopoverMenuGroup,
  PopoverMenuItem,
  PopoverTrigger,
  Spinner,
  Switch,
} from "@/components/Elements";
import { OutlineAsset } from "@/components/Elements/Asset/OutlineAsset";
import { ConfirmDeleteDialogButton } from "@/components/Elements/Dialog/ConfirmDeleteDialogButton";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import { useSidebarNavigationStore } from "@/components/Layout/SidebarToggle";
import { getGptInstructions } from "@/features/ai/api/getGptInstructions";
import { useGetGptOutline } from "@/features/ai/api/getGptOutline";
import { addParentToHeadings } from "@/features/ai/components/OutlineStep";
import { useSubscription } from "@/features/auth/api/getSubscription";
import { useUpdateDocument } from "@/features/documents/api/updateDocument";
import { getFraseDocumentTitle } from "@/features/documents/utils/getTitle";
import { useHandlePasteHeadings } from "@/features/documents/utils/pasting";
import { useAuth } from "@/lib/auth";
import { useDocumentStore } from "@/stores/document";
import { useNotificationStore } from "@/stores/notifications";
import { useSerpStore } from "@/stores/serp";
import { useDialogStore } from "@/stores/upgrade";
import { cn } from "@/utils/style";
import { PlusIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { debounce } from "lodash";
import { SearchIcon } from "lucide-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  TbCheck,
  TbChevronDown,
  TbClipboard,
  TbDots,
  TbLayoutSidebarRightCollapse,
  TbLayoutSidebarRightExpand,
  TbSparkles,
  TbSquareFilled,
} from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { WizardOutlineMenu } from "./WizardMenu";

const CancelToken = axios.CancelToken;
const cancelTokenSource = CancelToken.source();

interface TypeComboboxProps {
  selectedType: string;
  handleTypeChange: (type: string) => void;
}

interface HeaderFilterInputProps {
  filterValue: string;
  setFilterValue: (value: string) => void;
}

const typeOptions = [
  { label: "All", value: "all" },
  { label: "H2", value: "h2" },
  { label: "H3", value: "h3" },
  { label: "H4", value: "h4" },
  { label: "Question", value: "question" },
];

export const headerOptions = [
  { label: "H2", value: "h2" },
  { label: "H3", value: "h3" },
  { label: "H4", value: "h4" },
];

export const HeaderFilterInput: React.FC<HeaderFilterInputProps> = ({
  filterValue,
  setFilterValue,
}) => {
  return (
    <Input
      className="font-medium h-[32px] max-w-[20rem] flex-shrink-0"
      containerClassName="max-w-[20rem] h-[32px] flex-shrink-0"
      placeholder="Filter headings by keyword..."
      onChange={(value) => {
        setFilterValue(value);
      }}
      value={filterValue}
      startIcon={<SearchIcon />}
    />
  );
};

export const TypeCombobox: React.FC<TypeComboboxProps> = ({
  selectedType,
  handleTypeChange,
}) => {
  return (
    <Combobox
      value={selectedType}
      className="border border-zinc-900/10 dark:border-white/10 rounded-md h-[32px] ml-4 mr-8 flex-shrink-0"
      options={typeOptions.map((option) => ({
        ...option,
        onClick: () => handleTypeChange(option.value),
      }))}
    />
  );
};

const OutlineEmptyState = ({
  setSelectionDialogOpen,
  handleGenerateOutline,
}) => {
  return (
    <div className="flex flex-col items-center justify-center space-y-4 pt-16">
      <div className="fade-img-dark hidden dark:block">
        <img
          src={outline_group_dark}
          alt="Research"
          className="mx-auto w-[90%]"
        />
      </div>
      <div className="fade-img-light dark:hidden">
        <img
          src={outline_group_light}
          alt="Research"
          className="mx-auto w-[90%]"
        />
      </div>
      <p className="text-center text-sm text-zinc-600 dark:text-zinc-400 mx-4">
        Start by exploring headings from search results or generating an outline
        with AI.
      </p>
      <Button
        variant="primaryBlur"
        size="xs"
        className="w-auto"
        onClick={() => setSelectionDialogOpen(true)}
      >
        Explore headings
      </Button>
      <Button
        variant="aiBlur"
        onClick={handleGenerateOutline}
        className="w-auto"
        startIcon={<TbSparkles />}
        size="xs"
      >
        Generate headings with AI
      </Button>
    </div>
  );
};

interface HeaderComboboxProps {
  selectedHeader: string;
  handleHeaderChange: (header: string) => void;
  setHeaderComboboxOpen: (open: boolean) => void;
  headerComboboxOpen: boolean;
}

const HeaderCombobox: React.FC<HeaderComboboxProps> = ({
  selectedHeader,
  handleHeaderChange,
  setHeaderComboboxOpen,
  headerComboboxOpen,
}) => {
  return (
    <Popover open={headerComboboxOpen} onOpenChange={setHeaderComboboxOpen}>
      <PopoverTrigger asChild>
        <Button
          id="header-type-combobox"
          variant="outlineBlur"
          size="sm"
          textClassName={cn(
            "text-xs font-normal text-zinc-600 dark:text-zinc-200 flex-shrink-0"
          )}
          endIcon={<TbChevronDown />}
        >
          {selectedHeader.toUpperCase()}
        </Button>
      </PopoverTrigger>
      <PopoverContent sideOffset={5}>
        <PopoverMenuGroup
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {headerOptions.map((option) => (
            <PopoverMenuItem
              key={option.value}
              onClick={() => {
                handleHeaderChange(option.value);
              }}
            >
              {option.label}
              {option.value === selectedHeader ? (
                <span className="ml-2">
                  <TbCheck />
                </span>
              ) : (
                <span className="ml-5"></span>
              )}
            </PopoverMenuItem>
          ))}
        </PopoverMenuGroup>
      </PopoverContent>
    </Popover>
  );
};

interface ArticleListProps {
  validArticles: any[];
  handleAssetClick: (data: any) => void;
  selectedAssets: any;
}

const ArticleList: React.FC<ArticleListProps> = ({
  validArticles,
  handleAssetClick,
  selectedAssets,
}) => {
  if (validArticles.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <span className="text-zinc-500 text-sm">No articles found</span>
      </div>
    );
  }

  return (
    <ul className="inline-flex h-full space-x-4 px-4">
      {validArticles.map((article, index, array) => {
        // Calculate rank only for non-custom import articles
        const rank =
          array
            .slice(0, index)
            .filter((prevArticle) => !prevArticle.isCustomImport).length + 1;
        const links = article.links?.length ?? 0;

        if (article.assets?.length > 0) {
          return (
            <ArticleCard
              key={index}
              className="border rounded-md shadow-sm w-[23rem] bg-zinc-100/40 dark:bg-zinc-900/80 h-full"
              title={article.title}
              description={article.description}
              assets={article.assets}
              url={article.url}
              rank={article.isCustomImport ? null : rank}
              wordCount={article.word_count}
              links={links}
              domainAuthority={article.domainAuthority}
              onAssetClick={handleAssetClick}
              selectionEnabled={true}
              selectedAssets={selectedAssets}
              menuEnabled={false}
              isCustomImport={article.isCustomImport}
            />
          );
        }
      })}
    </ul>
  );
};

const GenerateWithAIButton = ({
  handleCancelGetInstructions,
  handleGenerateInstructions,
  headings,
  setShowInstructions,
  showInstructions,
  isGeneratingInstructions,
}) => {
  const hasInstructions = headings.some(
    (heading) =>
      heading.instruction && JSON.parse(heading.instruction).length > 0
  );
  if (hasInstructions && !isGeneratingInstructions) {
    return (
      <div className="flex items-center space-x-2">
        <Switch
          checked={!showInstructions}
          onCheckedChange={(checked) => {
            setShowInstructions(!checked);
          }}
          className="ring-zinc-300"
          size="sm"
        />
        <span className="text-xs text-zinc-900 dark:text-zinc-400">
          Hide instructions
        </span>
      </div>
    );
  } else {
    if (isGeneratingInstructions) {
      return (
        <Button
          variant="aiBlur"
          onClick={handleCancelGetInstructions}
          startIcon={<TbSquareFilled />}
          endIcon={<Spinner />}
          size="xs"
          className="w-auto"
        >
          Stop generating
        </Button>
      );
    } else {
      return (
        <Button
          variant="aiBlur"
          onClick={handleGenerateInstructions}
          startIcon={<TbSparkles />}
          size="xs"
          className="w-auto"
        >
          Generate instructions with AI
        </Button>
      );
    }
  }
};

interface ControlButtonsProps {
  selectedAssets: any;
  setSelectedAssets: (assets: any) => void;
  setSelectionlOpen: (open: boolean) => void;
  handlePasteHeadings: (assets: any[]) => void;
  handleSaveOutline: () => void;
  handleUpdateDocument: (assets: any[]) => void;
}

const ControlButtons: React.FC<ControlButtonsProps> = ({
  selectedAssets,
  setSelectedAssets,
  setSelectionDialogOpen,
  handlePasteHeadings,
  handleUpdateDocument,
}) => {
  const { data: subscriptionData, isLoading: isLoadingSubscription } =
    useSubscription({});
  const isAddOnActive = subscriptionData?.add_on;
  const navigate = useNavigate();

  return (
    <div className="flex items-center">
      <Button
        variant="outlineBlur"
        size="xs"
        textClassName="text-2xs"
        onClick={() => {
          setSelectionDialogOpen(true);
        }}
        className="ml-2"
      >
        View Headings
      </Button>
      <Button
        variant="outlineBlur"
        size="xs"
        textClassName="text-2xs"
        disabled={isLoadingSubscription}
        isLoading={isLoadingSubscription}
        onClick={() => {
          if (!isAddOnActive) {
            useDialogStore.getState().openDialog("aiArticleUpgrade", "test");
          } else {
            navigate("/app/wizard", {
              state: { shouldPrepopulate: true },
            });
          }
        }}
        className="ml-2"
      >
        AI Article
      </Button>
      <Button
        variant="buttonIcon"
        buttonIcon={<TbClipboard />}
        onClick={() => {
          handlePasteHeadings(selectedAssets);
        }}
        className="ml-2"
        tooltipContent="Paste headings to editor"
      ></Button>
      <OutlineMenu
        handleUpdateDocument={handleUpdateDocument}
        setSelectedAssets={setSelectedAssets}
      />
    </div>
  );
};

interface CustomHeaderInputProps {
  customHeaderInputVisible: boolean;
  setCustomHeaderInputVisible: (visible: boolean) => void;
  selectedHeader: string;
  handleHeaderChange: (header: string) => void;
  customHeaderValue: string;
  setCustomHeaderValue: (value: string) => void;
  handleAddCustomHeader: () => void;
  hidden: boolean;
  containerClassName?: string;
  setIsIndexInsert: (isIndexInsert: boolean) => void;
}

export const CustomHeaderInput: React.FC<CustomHeaderInputProps> = ({
  customHeaderInputVisible,
  setCustomHeaderInputVisible,
  selectedHeader,
  handleHeaderChange,
  customHeaderValue,
  setCustomHeaderValue,
  handleAddCustomHeader,
  hidden,
  containerClassName,
  setIsIndexInsert,
}) => {
  const [headerComboboxOpen, setHeaderComboboxOpen] = useState(false);
  const customHeaderInputRef = React.useRef(null);

  useEffect(() => {
    if (customHeaderInputVisible && !headerComboboxOpen) {
      setTimeout(() => {
        customHeaderInputRef.current?.focus();
      }, 100);
    }
  }, [customHeaderInputVisible, headerComboboxOpen]);

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      if (customHeaderValue === "") {
        setCustomHeaderInputVisible(false);
      } else {
        handleAddCustomHeader();
      }
    } else if (event.key === "Escape") {
      setCustomHeaderInputVisible(false);
      setIsIndexInsert(false);
    }
  };

  if (hidden) {
    return null;
  }

  if (!customHeaderInputVisible) {
    return (
      <Button
        variant="textTab"
        startIcon={<PlusIcon />}
        onClick={() => {
          setCustomHeaderInputVisible(true);
          setIsIndexInsert(false);
        }}
        className={cn(
          "text-zinc-400 dark:text-zinc-400 hover:text-zinc-500 dark:hover:text-zinc-500 w-fit flex-shrink-0",
          containerClassName
        )}
        textClassName="text-zinc-400 dark:text-zinc-600 hover:text-zinc-500 dark:hover:text-zinc-500 px-1"
        size="2xs"
      >
        Add heading
      </Button>
    );
  }

  return (
    <div
      className={cn(
        "transition-all flex items-center space-x-2 h-8 w-full",
        containerClassName
      )}
    >
      <HeaderCombobox
        selectedHeader={selectedHeader}
        handleHeaderChange={handleHeaderChange}
        headerComboboxOpen={headerComboboxOpen}
        setHeaderComboboxOpen={setHeaderComboboxOpen}
      />
      <Input
        ref={customHeaderInputRef}
        className="w-full h-8"
        placeholder="Add header..."
        onKeyDown={handleKeyUp}
        value={customHeaderValue}
        onChange={(value) => {
          setCustomHeaderValue(value);
        }}
        onBlur={(event) => {
          if (event.relatedTarget?.id === "header-type-combobox") {
            return;
          }

          setCustomHeaderInputVisible(false);
          setCustomHeaderValue("");
        }}
      />
    </div>
  );
};

export function OutlineMenu({ handleUpdateDocument, setSelectedAssets }) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [hasOpenDialog, setHasOpenDialog] = useState(false);
  const dropdownTriggerRef = React.useRef(null);

  const handleDialogItemSelect = () => {
    dropdownTriggerRef.current?.focus();
  };

  const handleDialogItemOpenChange = (open) => {
    setHasOpenDialog(open);
    if (!open) {
      setDropdownOpen(false);
    }

    // Delay restoring pointer events
    setTimeout(() => {
      document.body.style.pointerEvents = "auto";
    }, 2000);
  };

  return (
    <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="buttonIcon"
          size="sm"
          className="ml-1.5"
          buttonIcon={<TbDots />}
        ></Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent hidden={hasOpenDialog}>
        <DropdownMenuGroup>
          <ConfirmDeleteDialogButton
            title="Clear headings"
            description="Are you sure you want to clear all headings from the outline? This action cannot be undone."
            triggerButtonLabel="Clear headings"
            confirmButtonLabel="Clear headings"
            onConfirm={() => {
              setSelectedAssets((prevAssets) => {
                handleUpdateDocument([]).catch(() => {
                  setSelectedAssets(prevAssets);
                });
                return [];
              });
            }}
            triggerButtonProps={{
              variant: "text",
              size: "xs",
              asDropdownItem: true,
              onOpenChange: handleDialogItemOpenChange,
            }}
          />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

interface OutlineProps {
  panelWidth: number;
}

export const Outline: React.FC<OutlineProps> = ({
  panelWidth,
  variant = "editor",
  onSelectedAssetsChange,
  headings,
  title,
  selectedLanguage,
  topics,
  urls,
  global_instructions,
  setIsGeneratingInstructions: setIsGeneratingInstructionsProp,
  isSticky,
  containerRef,
}: {
  panelWidth: number;
  variant?: "editor" | "wizard";
  onSelectedAssetsChange?: (selectedAssets: any[]) => void;
  headings?: any[];
  title?: string;
  selectedLanguage?: string;
  topics?: any[];
  urls?: string[];
  global_instructions?: string[];
  setIsGeneratingInstructions?: (isGeneratingInstructions: boolean) => void;
  isSticky?: boolean;
  containerRef?: React.RefObject<HTMLDivElement>;
}) => {
  const cancelTokenSource = useRef(axios.CancelToken.source());
  const handlePasteHeadings = useHandlePasteHeadings();
  const { document: fraseDocument, setDocument } = useDocumentStore();
  const { serp } = useSerpStore();
  const [selectionDialogOpen, setSelectionDialogOpen] = useState(false);
  const [selectedType, setSelectedType] = useState("all");
  const [selectedHeader, setSelectedHeader] = useState("h2");
  const [customHeaderValue, setCustomHeaderValue] = useState("");
  const [customHeaderIndex, setCustomHeaderIndex] = useState(0);
  const [isIndexInsert, setIsIndexInsert] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState(
    () => headings?.slice(0, 29) || []
  );
  const [showInstructions, setShowInstructions] = useState(
    variant === "wizard"
  );
  const [showArticleInstructions, setShowArticleInstructions] = useState(false);
  const [loadingInstructions, setLoadingInstructions] = useState(
    new Array(headings?.length).fill(false)
  );
  const outlineRef = useRef<HTMLDivElement>(null);

  const [activeIndex, setActiveIndex] = useState(0);
  const [customHeaderInputVisible, setCustomHeaderInputVisible] =
    useState(false);
  const { id: docId, metadata } = fraseDocument;
  const { closeSidebar, openSidebar, isSidebarOpen } =
    useSidebarNavigationStore();
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
    isResolvingConflict: true,
  });
  const { addNotification } = useNotificationStore();
  const [isGeneratingOutline, setIsGeneratingOutline] = useState(false);
  const [isGeneratingInstructions, setIsGeneratingInstructions] =
    useState(false);
  const { user } = useAuth();
  const userGeography = JSON.parse(user?.geography || "{}");

  const currentSerp = serp[docId] || {
    results: [],
    articles: [],
  };
  const [searchValue, setSearchValue] = useState("");
  const totalHeadingsCount = selectedAssets.length + (title ? 1 : 0);

  const generateOutlineQuery = useGetGptOutline({
    title: title || getFraseDocumentTitle(fraseDocument),
    topics: topics?.map((topic) => topic.entity).join(", "),
    lang: selectedLanguage || userGeography?.language || "en",
    serp: currentSerp.articles.map((article) => {
      return {
        title: article.title,
        url: article.url,
        snippet: article.clean_text?.slice(0, 50) + "...",
        author: article.author,
        date_created: article.dateCreated,
        image: (article.images?.length > 0 && article.images[0]) || "",
      };
    }),
    docHash: fraseDocument.hash,
    config: {
      enabled: false,
    },
  });
  const blacklist = fraseDocument.metadata?.blacklist || {};

  const validArticles = useMemo(() => {
    return (currentSerp.articles || [])
      .filter((article) => article.isValid && !blacklist[article.url])
      .sort((a, b) => {
        // Place custom import articles at the beginning
        if (a.isCustomImport && !b.isCustomImport) return -1;
        if (!a.isCustomImport && b.isCustomImport) return 1;
        return a.index - b.index;
      })
      .map((article) => ({
        ...article,
        assets:
          article &&
          article.assets &&
          article.assets
            .filter((asset) => {
              if (selectedType === "all") {
                return true;
              } else if (selectedType === "question") {
                return asset.header.endsWith("?");
              } else {
                return (
                  asset.header_tag &&
                  asset.header_tag.toLowerCase() === selectedType.toLowerCase()
                );
              }
            })
            .filter((asset) =>
              asset.header.toLowerCase().includes(searchValue.toLowerCase())
            ),
      }))
      .sort((a, b) => a.index - b.index)
      .map((article, index, array) => {
        // Calculate rank only for non-custom import articles
        const rank =
          array
            .slice(0, index)
            .filter((prevArticle) => !prevArticle.isCustomImport).length + 1;
        return {
          ...article,
          rank: article.isCustomImport ? null : rank,
        };
      });
  }, [currentSerp.articles, searchValue, selectedType]);

  const validArticlesWithAssets = useMemo(() => {
    return validArticles.filter(
      (article) => article.assets && article.assets.length > 0
    );
  }, [validArticles]);

  const fetchInstructionsForHeading = async (
    heading,
    index,
    headingsWithParents,
    cancelToken
  ) => {
    if (!heading) return;
    setLoadingInstructions((currentLoading) => {
      const newLoading = [...currentLoading];
      newLoading[index] = true; // Set loading to true for the current heading
      return newLoading;
    });

    try {
      const data = await getGptInstructions({
        title,
        topics: topics?.map((topic) => topic.entity).join(", "),
        focus_heading: heading.header,
        outline: headingsWithParents.map((heading) => {
          return {
            id: heading.id,
            heading: heading.header,
            heading_type: heading.user_header_tag,
            instructions: heading.instruction,
            parent: heading.parent,
          };
        }),
        lang: selectedLanguage || userGeography?.language || "en",
        urls: urls,
        docHash: fraseDocument.hash,
        cancelToken: cancelToken, // Pass the cancel token
      });

      if (data !== undefined) {
        if (data.instructions && Array.isArray(data.instructions)) {
          handleAddInstruction(heading.id, data.instructions);
        }
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log(`Request canceled for heading ${index}.`);
      } else {
        addNotification({
          title: "Error generating instructions",
          message: `We were unable to generate instructions for the following heading: ${headings[index].header}. Please try again.`,
          type: "error",
        });
      }
    } finally {
      setLoadingInstructions((currentLoading) => {
        const newLoading = [...currentLoading];
        newLoading[index] = false; // Set loading to false for the current heading
        return newLoading;
      });
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    // Reorder the selected assets
    const reorderedAssets = Array.from(selectedAssets);
    const [removed] = reorderedAssets.splice(result.source.index, 1);
    reorderedAssets.splice(result.destination.index, 0, removed);

    setSelectedAssets(reorderedAssets);
    handleUpdateDocument(reorderedAssets);
  };

  const handleUpdateDocument = useCallback(
    (assets) => {
      return new Promise<void>((resolve, reject) => {
        const debouncedUpdate = debounce(async () => {
          try {
            await updateDocumentMutation.mutateAsync({
              ...fraseDocument,
              metadata: {
                ...fraseDocument.metadata,
                outline: JSON.stringify(
                  assets.reduce((map, asset, index) => {
                    map[index] = {
                      id: asset.id,
                      header: asset.header,
                      url: asset.url,
                      user_header_tag: asset.user_header_tag,
                      instruction: asset.instruction,
                    };
                    return map;
                  }, {})
                ),
              },
            });
            resolve();
          } catch (error) {
            reject(error);
          }
        }, 300);

        debouncedUpdate();
      });
    },
    [updateDocumentMutation, fraseDocument]
  );

  const handleAssetClick = useCallback(
    (asset) => {
      // Optimistically update the state
      setSelectedAssets((prevAssets) => {
        const existingAssetIndex = prevAssets.findIndex(
          (prevAsset) =>
            prevAsset.header === asset.header && prevAsset.url === asset.url
        );

        let updatedAssets;
        if (existingAssetIndex !== -1) {
          // Asset is already selected, remove it from the array
          updatedAssets = [
            ...prevAssets.slice(0, existingAssetIndex),
            ...prevAssets.slice(existingAssetIndex + 1),
          ];
        } else {
          // Asset is not selected, add it to the array with a new ID
          const newAsset = {
            ...asset,
            id: Math.max(0, ...prevAssets.map((a) => a.id)) + 1, // Ensure a unique ID
            selected: true,
          };
          updatedAssets = [...prevAssets, newAsset];
        }

        // Perform the server update
        handleUpdateDocument(updatedAssets).catch(() => {
          // If the server update fails, revert to the previous state
          setSelectedAssets(prevAssets);
        });

        return updatedAssets;
      });
    },
    [handleUpdateDocument]
  );

  const handleAddInstruction = useCallback(
    (headerId: number, newInstructions: string[]) => {
      setSelectedAssets((prevAssets) => {
        const updatedAssets = prevAssets.map((asset) => {
          if (asset.id === headerId) {
            // Combine existing instructions with new instructions
            const instructions = asset.instruction
              ? JSON.parse(asset.instruction).concat(newInstructions)
              : newInstructions;
            return { ...asset, instruction: JSON.stringify(instructions) };
          }
          return asset;
        });

        handleUpdateDocument(updatedAssets).catch(() => {
          setSelectedAssets(prevAssets);
        });

        return updatedAssets;
      });
    },
    [handleUpdateDocument]
  );

  const handleUpdateInstruction = useCallback(
    (headerId, instructions) => {
      setSelectedAssets((prevAssets) => {
        const updatedAssets = prevAssets.map((asset) =>
          asset.id === headerId
            ? { ...asset, instruction: JSON.stringify(instructions) }
            : asset
        );

        handleUpdateDocument(updatedAssets).catch(() => {
          setSelectedAssets(prevAssets);
        });

        return updatedAssets;
      });
    },
    [handleUpdateDocument]
  );

  const handleUpdateGlobalInstruction = useCallback(
    (instructions) => {
      return new Promise<void>((resolve, reject) => {
        const debouncedUpdate = debounce(async () => {
          try {
            await updateDocumentMutation.mutateAsync({
              ...fraseDocument,
              metadata: {
                ...fraseDocument.metadata,
                global_instructions: JSON.stringify(instructions),
              },
            });
            resolve();
          } catch (error) {
            reject(error);
          }
        }, 300);

        debouncedUpdate();
      });
    },
    [updateDocumentMutation, fraseDocument]
  );

  const handleAddCustomHeader = useCallback(() => {
    if (customHeaderValue && selectedAssets.length < 29) {
      const newId = Math.max(0, ...selectedAssets.map((a) => a.id)) + 1;
      const customHeader = {
        header: customHeaderValue,
        url: "",
        user_header_tag: selectedHeader || "h2",
        instruction: "",
        id: newId,
      };

      setSelectedAssets((prevAssets) => {
        const updatedAssets = [...prevAssets, customHeader];
        handleUpdateDocument(updatedAssets).catch(() => {
          setSelectedAssets(prevAssets);
        });
        return updatedAssets;
      });

      setCustomHeaderValue("");
      setSelectedHeader("h2");
      setCustomHeaderInputVisible(false);
    } else {
      addNotification({
        title: "Heading limit reached",
        message:
          "You've hit the cap of 30 headings. Please refine your existing headings before adding more.",
        type: "error",
      });
    }
  }, [
    selectedHeader,
    customHeaderValue,
    handleUpdateDocument,
    selectedAssets.length,
  ]);

  const handleUpdateHeaderType = useCallback(
    (headerId, headerType) => {
      setSelectedAssets((prevAssets) => {
        const updatedAssets = prevAssets.map((asset) =>
          asset.id === headerId
            ? { ...asset, user_header_tag: headerType }
            : asset
        );

        handleUpdateDocument(updatedAssets).catch(() => {
          setSelectedAssets(prevAssets);
        });

        return updatedAssets;
      });
    },
    [handleUpdateDocument]
  );

  const handleDeleteHeader = useCallback(
    (headerId) => {
      setSelectedAssets((prevAssets) => {
        const updatedAssets = prevAssets.filter(
          (asset) => asset.id !== headerId
        );

        handleUpdateDocument(updatedAssets).catch(() => {
          setSelectedAssets(prevAssets);
        });

        return updatedAssets;
      });
    },
    [handleUpdateDocument]
  );

  const handleRenameHeader = useCallback(
    (headerId, newHeaderText) => {
      setSelectedAssets((prevAssets) => {
        const updatedAssets = prevAssets.map((asset) =>
          asset.id === headerId ? { ...asset, header: newHeaderText } : asset
        );

        handleUpdateDocument(updatedAssets).catch(() => {
          setSelectedAssets(prevAssets);
        });

        return updatedAssets;
      });
    },
    [handleUpdateDocument]
  );

  const handleInsertNewHeader = useCallback(
    (headerText, headerType, index) => {
      if (selectedAssets.length < 29) {
        setSelectedAssets((prevAssets) => {
          const newId = Math.max(0, ...prevAssets.map((a) => a.id)) + 1;
          const newHeader = {
            header: headerText,
            url: "",
            user_header_tag: headerType,
            instruction: "",
            id: newId,
          };
          const updatedAssets = [
            ...prevAssets.slice(0, index),
            newHeader,
            ...prevAssets.slice(index),
          ];

          handleUpdateDocument(updatedAssets).catch(() => {
            setSelectedAssets(prevAssets);
          });

          return updatedAssets;
        });
        setSelectedType(headerType);
        setCustomHeaderIndex(index);
        setIsIndexInsert(false);
      } else {
        addNotification({
          title: "Heading limit reached",
          message:
            "You've hit the cap of 30 headings. Please refine your existing headings before adding more.",
          type: "error",
        });
      }
    },
    [handleUpdateDocument, selectedAssets.length]
  );

  const handleGenerateInstructionForHeading = useCallback(
    (headingId) => {
      const heading = selectedAssets.find((asset) => asset.id === headingId);
      const headingIndex = selectedAssets.findIndex(
        (asset) => asset.id === headingId
      );
      const headingsWithParents = addParentToHeadings(selectedAssets);
      if (heading) {
        fetchInstructionsForHeading(
          heading,
          headingIndex,
          headingsWithParents,
          cancelTokenSource.current.token
        );
      }
    },
    [fetchInstructionsForHeading]
  );

  const handleGenerateInstructions = useCallback(async () => {
    setIsGeneratingInstructions(true);
    setIsGeneratingInstructionsProp && setIsGeneratingInstructionsProp(true);
    const headingsWithParents = addParentToHeadings(selectedAssets);
    const cancelToken = cancelTokenSource.current.token; // Get the current cancel token
    try {
      for (let i = 0; i < selectedAssets.length; i++) {
        if (cancelToken !== cancelTokenSource.current.token) {
          // If the cancel token has changed, stop generating instructions
          break;
        }
        await fetchInstructionsForHeading(
          selectedAssets[i],
          i,
          headingsWithParents,
          cancelToken
        );
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Operation canceled by the user.");
      }
    } finally {
      setIsGeneratingInstructions(false);
      setIsGeneratingInstructionsProp && setIsGeneratingInstructionsProp(false);
    }
  }, [selectedAssets, fetchInstructionsForHeading, handleAddInstruction]);

  const handleGenerateOutline = useCallback(async () => {
    setIsGeneratingOutline(true);
    try {
      const response = await generateOutlineQuery.refetch();
      const generatedOutline = response?.data.outline;

      if (generatedOutline) {
        setSelectedAssets((prevAssets) => {
          const processedGeneratedOutline = generatedOutline
            .slice(0, 29)
            .map((heading, index) => ({
              header: heading.header,
              url: "",
              user_header_tag: heading.user_header_tag,
              instruction: "",
              id: prevAssets.length + index,
            }));

          handleUpdateDocument(processedGeneratedOutline).catch(() => {
            setSelectedAssets(prevAssets);
          });

          return processedGeneratedOutline;
        });
      }
    } catch (error) {
      // Handle error appropriately
    }
    setIsGeneratingOutline(false);
  }, [generateOutlineQuery, handleUpdateDocument]);

  useEffect(() => {
    if (onSelectedAssetsChange) {
      onSelectedAssetsChange(selectedAssets);
    }
  }, [selectedAssets]);

  const handleCancelGetInstructions = () => {
    setIsGeneratingInstructions(false);
    cancelTokenSource.current.cancel("Request canceled by the user.");
    cancelTokenSource.current = axios.CancelToken.source(); // Create a new token source
  };

  useEffect(() => {
    return () => {
      handleCancelGetInstructions();
    };
  }, []);

  const handleTypeChange = (type: string) => {
    setSelectedType(type);
  };

  const handleHeaderChange = (header: string) => {
    setSelectedHeader(header);
  };

  if (isGeneratingOutline) {
    if (variant === "editor") {
      return (
        <div className="relative flex items-center justify-center w-full h-[25%]">
          <LogoSpinner
            variant="md"
            loadingText={"Generating outline with AI..."}
          />
        </div>
      );
    } else {
      return (
        <div className="relative flex items-center justify-center w-full h-[25%]">
          <LogoSpinner
            variant="md"
            loadingText={"Generating outline with AI..."}
          />
        </div>
      );
    }
  }

  return (
    <div
      ref={outlineRef}
      className={cn(
        "space-y-2 mb-20",
        variant === "editor" && "pl-4 pr-1 flex flex-col",
        variant === "wizard" && "mx-auto flex flex-col"
      )}
      style={{
        width: variant === "wizard" ? `calc(60%)` : `100%`,
        maxWidth: variant === "wizard" ? `700px` : `100%`,
      }}
    >
      <div className="flex flex-col mb-20 space-y-2">
        <DialogModal
          open={selectionDialogOpen}
          setOpen={setSelectionDialogOpen}
          panelWidth={panelWidth}
          variant="fullScreen"
          className="h-full w-full my-2 ml-2"
          contentClassName="px-0"
          header={
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center ">
                <HeaderFilterInput
                  filterValue={searchValue}
                  setFilterValue={setSearchValue}
                />
                <TypeCombobox
                  selectedType={selectedType}
                  handleTypeChange={handleTypeChange}
                />
              </div>
              <div className="flex items-center">
                {isSidebarOpen ? (
                  <Button
                    variant="buttonIcon"
                    buttonIcon={<TbLayoutSidebarRightCollapse />}
                    onClick={() => {
                      closeSidebar();
                    }}
                    size="lg"
                    tooltipContent="Close sidebar"
                    className="mr-2 flex-shrink-0"
                  ></Button>
                ) : (
                  <Button
                    variant="buttonIcon"
                    buttonIcon={<TbLayoutSidebarRightExpand />}
                    onClick={() => {
                      openSidebar();
                    }}
                    size="lg"
                    tooltipContent="Open sidebar"
                    className="mr-2 flex-shrink-0"
                  ></Button>
                )}
                <DialogCloseButton
                  close={() => setSelectionDialogOpen(false)}
                />
              </div>
            </div>
          }
        >
          {validArticlesWithAssets.length > 0 ? (
            <div className="overflow-y-hidden overflow-x-scroll h-full pb-2">
              <ArticleList
                validArticles={validArticles}
                handleAssetClick={handleAssetClick}
                selectedAssets={selectedAssets}
              />
            </div>
          ) : (
            <div className="flex items-center justify-center h-full">
              <p className="text-zinc-500 text-sm">
                No matching headings found. Please try refining your search.
              </p>
            </div>
          )}
        </DialogModal>
        {selectedAssets.length > 0 && variant === "editor" ? (
          <div className="flex justify-between items-center pb-1.5 pt-0.5">
            <div className="flex">
              <div className="flex-col">
                <span className="font-semibold text-zinc-800 dark:text-zinc-200">
                  {totalHeadingsCount}
                </span>
                <span className="text-xs text-zinc-500 ml-1">headings</span>
              </div>
            </div>
            <ControlButtons
              selectedAssets={selectedAssets}
              setSelectedAssets={setSelectedAssets}
              setSelectionDialogOpen={setSelectionDialogOpen}
              handlePasteHeadings={handlePasteHeadings}
              handleUpdateDocument={handleUpdateDocument}
            />
          </div>
        ) : (
          variant === "wizard" && (
            <div
              className={cn(
                "transition-shadow flex justify-between items-center sticky -top-4 left-0 right-0 z-50 bg-white dark:bg-zinc-900",
                isSticky &&
                  "shadow-sm py-2 border-b dark:border-zinc-800 dark:shadow-lg absolute mx-auto"
              )}
              style={{
                width: isSticky ? containerRef?.current?.clientWidth : "100%",
                marginLeft: isSticky ? containerRef?.current?.offsetLeft : 0,
                marginTop: isSticky ? 79 : 0,
              }}
            >
              <div
                className="flex w-full justify-between items-center mx-auto"
                style={{
                  width: outlineRef.current?.clientWidth,
                }}
              >
                <div className="flex">
                  <div className="flex-col">
                    <span className="font-semibold text-zinc-800 dark:text-zinc-200">
                      {totalHeadingsCount}
                    </span>
                    <span className="text-xs text-zinc-500 ml-1">headings</span>
                  </div>
                  <div className="flex-col ml-2">
                    <span className="text-xs text-zinc-400 dark:text-zinc-600">
                      (
                      {selectedAssets.filter(
                        (asset) =>
                          asset.instruction &&
                          JSON.parse(asset.instruction).length > 0
                      ).length +
                        (global_instructions && global_instructions?.length > 0
                          ? 1
                          : 0)}{" "}
                      / {selectedAssets.length + (title ? 1 : 0)}
                    </span>
                    <span className="text-xs text-zinc-400 dark:text-zinc-600 ml-1">
                      have instructions)
                    </span>
                  </div>
                </div>
                <div className="flex items-center">
                  {selectedAssets.length > 0 && (
                    <GenerateWithAIButton
                      handleCancelGetInstructions={handleCancelGetInstructions}
                      showInstructions={showInstructions}
                      handleGenerateInstructions={handleGenerateInstructions}
                      setShowInstructions={setShowInstructions}
                      headings={selectedAssets}
                      isGeneratingInstructions={isGeneratingInstructions}
                    />
                  )}
                  {variant === "wizard" && (
                    <WizardOutlineMenu
                      selectedAssets={selectedAssets}
                      setSelectedAssets={setSelectedAssets}
                      handleUpdateDocument={handleUpdateDocument}
                    />
                  )}
                </div>
              </div>
            </div>
          )
        )}
        {selectedAssets.length === 0 && variant === "editor" ? (
          <OutlineEmptyState
            setSelectionDialogOpen={setSelectionDialogOpen}
            handleGenerateOutline={handleGenerateOutline}
          />
        ) : null}

        {(selectedAssets.length > 0 || variant === "wizard") && title && (
          <OutlineAsset
            headerText={title}
            user_header_tag="h1"
            id={0}
            showAssetMenu={false}
            showDeleteButton={false}
            isTitleAsset={true}
            showAddInstructionButton={false}
            showAddArticleInstructionButton={true}
            handleUpdateGlobalInstruction={handleUpdateGlobalInstruction}
            globalInstructions={global_instructions}
            showInstructions={showInstructions}
            showArticleInstructions={showArticleInstructions}
          />
        )}

        {selectedAssets.length > 0 && (
          <DragDropContext DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="assetSelected">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="space-y-2"
                >
                  {selectedAssets.map((asset, index) => (
                    <React.Fragment key={index}>
                      <Draggable
                        key={index}
                        draggableId={String(index)}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <OutlineAsset
                            key={index}
                            index={String(index)}
                            innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            headerText={asset.header}
                            headerType={asset.user_header_tag}
                            headerId={asset.id}
                            header={asset}
                            isDragging={snapshot.isDragging}
                            draggableStyle={provided.draggableProps.style}
                            selectionEnabled={true}
                            onClick={handleAssetClick}
                            placeholder={provided.placeholder}
                            handleUpdateHeaderType={handleUpdateHeaderType}
                            handleDeleteHeader={handleDeleteHeader}
                            handleRenameHeader={handleRenameHeader}
                            handleInsertNewHeader={handleInsertNewHeader}
                            setCustomHeaderInputVisible={
                              setCustomHeaderInputVisible
                            }
                            handleHeaderChange={handleHeaderChange}
                            handleAddInstruction={handleAddInstruction}
                            handleUpdateInstruction={handleUpdateInstruction}
                            setCustomHeaderIndex={setCustomHeaderIndex}
                            setCustomHeaderValue={setCustomHeaderValue}
                            customHeaderValue={customHeaderValue}
                            selectedHeader={selectedHeader}
                            setIsIndexInsert={setIsIndexInsert}
                            setActiveIndex={setActiveIndex}
                            activeIndex={activeIndex}
                            setSelectedHeader={setSelectedHeader}
                            showAddInstructionButton={true}
                            showInstructions={showInstructions}
                            instruction={asset.instruction}
                            loadingInstructions={loadingInstructions}
                            isGeneratingInstructions={isGeneratingInstructions}
                            handleGenerateInstructionForHeading={
                              handleGenerateInstructionForHeading
                            }
                            globalInstructions={global_instructions}
                          ></OutlineAsset>
                        )}
                      </Draggable>
                    </React.Fragment>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}

        {variant === "wizard" ? (
          <CustomHeaderInput
            customHeaderInputVisible={
              selectedAssets.length === 0 || customHeaderInputVisible
            }
            setCustomHeaderInputVisible={setCustomHeaderInputVisible}
            selectedHeader={selectedHeader}
            handleHeaderChange={handleHeaderChange}
            customHeaderValue={customHeaderValue}
            setCustomHeaderValue={setCustomHeaderValue}
            handleAddCustomHeader={handleAddCustomHeader}
            hidden={false}
            setIsIndexInsert={setIsIndexInsert}
          />
        ) : (
          <CustomHeaderInput
            customHeaderInputVisible={customHeaderInputVisible}
            setCustomHeaderInputVisible={setCustomHeaderInputVisible}
            selectedHeader={selectedHeader}
            handleHeaderChange={handleHeaderChange}
            customHeaderValue={customHeaderValue}
            setCustomHeaderValue={setCustomHeaderValue}
            handleAddCustomHeader={handleAddCustomHeader}
            hidden={selectedAssets.length === 0 || isIndexInsert}
            setIsIndexInsert={setIsIndexInsert}
          />
        )}

        {variant === "wizard" && selectedAssets.length === 0 && (
          <Button
            variant="aiBlur"
            onClick={handleGenerateOutline}
            className="w-fit mt-4"
            startIcon={<TbSparkles />}
            size="xs"
          >
            Generate outline with AI
          </Button>
        )}
        <div className="h-20"></div>
      </div>
    </div>
  );
};
