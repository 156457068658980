import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Elements";
import { FraseResearchBot } from "@/components/Elements/Logo/FraseResearchBot";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import { ContentLayout } from "@/components/Layout";
import { useNotificationStore } from "@/stores/notifications";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SeoAnalyticsDashboard from "../components/SeoAnalyticsDashboard";
import SelectSiteDialog from "../components/SeoAnalyticsDashboard/components/SelectSiteDialog";
import UpgradeToContinueDialog from "../components/SeoAnalyticsDashboard/components/UpgradeToContinueDialog";
import { useSeoAnalyticsStore } from "../store";

export const SeoAnalytics = () => {
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const {
    isInit,
    isGSCEnabled,
    integrateGSC,
    init,
    domainLimit,
    disableSearchConsole,
    showUpgradeToContinueDialog,
    showSelectSiteDialog,
    setShowSelectSiteDialog,
    setShowUpgradeToContinueDialog,
  } = useSeoAnalyticsStore();

  useEffect(() => {
    init();
  }, [init]);

  if (!isInit) {
    return (
      <ContentLayout title="Content Analytics">
        <div className="relative flex items-center w-full h-full justify-center">
          <LogoSpinner variant="md" loadingText="Loading SEO Analytics..." />
        </div>
      </ContentLayout>
    );
  }

  if (!isGSCEnabled) {
    return (
      <ContentLayout title="Content Analytics">
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="bg-white dark:bg-zinc-800 rounded-md p-10 shadow-glow max-w-lg border space-y-4 dark:border-zinc-700">
            <FraseResearchBot />
            <div className="space-y-4">
              <h1 className="text-lg font-medium dark:text-white">
                SEO Content Analytics
              </h1>
              <p className="text-[15px] font-normal text-zinc-600 dark:text-zinc-200">
                Measure growth, avoid decay, and find new opportunities with
                Google Search Console.
              </p>
              <div>
                {domainLimit > 0 ? (
                  <Button
                    onClick={() => {
                      integrateGSC(navigate, addNotification);
                    }}
                  >
                    Integrate Google Search Console
                  </Button>
                ) : (
                  <>
                    <Table className="w-full mb-4">
                      <TableHeader className="bg-zinc-50 dark:bg-zinc-800">
                        <TableRow>
                          <TableHead className="w-1/2 py-1 px-4 text-left text-xs font-semibold text-zinc-900 dark:text-white">
                            Plan
                          </TableHead>
                          <TableHead className="w-1/2 py-1 px-4 text-right text-xs font-semibold text-zinc-900 dark:text-white">
                            Domains
                          </TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        <TableRow>
                          <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                            Free / Solo
                          </TableCell>
                          <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                            0
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                            Basic
                          </TableCell>
                          <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                            1
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="py-1 px-4 text-xs text-zinc-700 dark:text-zinc-300">
                            Team
                          </TableCell>
                          <TableCell className="py-1 px-4 text-xs text-right text-zinc-700 dark:text-zinc-300">
                            Unlimited
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <Button
                      variant="primaryBlur"
                      size="sm"
                      className="bg-emerald-100"
                      onClick={() => {
                        navigate("/app/settings/plans");
                      }}
                    >
                      Upgrade your plan to continue
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </ContentLayout>
    );
  }

  return (
    <>
      <SeoAnalyticsDashboard />
      <SelectSiteDialog
        open={showSelectSiteDialog}
        onClose={() => setShowSelectSiteDialog(false)}
        onCancel={disableSearchConsole}
      />
      <UpgradeToContinueDialog
        open={showUpgradeToContinueDialog}
        onClose={() => setShowUpgradeToContinueDialog(false)}
        onCancel={disableSearchConsole}
      />
    </>
  );
};
