import { useIsDarkMode } from "@/utils/isDarkMode";
import { $unwrapMarkNode } from "@lexical/mark";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createRangeSelection,
  $getRoot,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isTextNode,
  $nodesOfType,
  LexicalNode,
  RangeSelection,
  TextNode,
} from "lexical";
import { debounce } from "lodash";
import { useEffect } from "react";
import { FraseDocument } from "../../../../features/documents";
import { useEditorStore } from "../../../../stores/editor";
import { useTopicsStore } from "../../../../stores/topics";
import { $createMarkNode, $isMarkNode, MarkNode } from "../../nodes/MarkNode";

const lightModeHighlightColorMap = {
  amber: "background-color: #fef3c7; border-bottom: 2px solid #f59e0b;",
  emerald: "background-color: #d1fae5; border-bottom: 2px solid #10b981;",
  rose: "background-color: #fef2f2; border-bottom: 2px solid #f43f5e;",
  zinc: "background-color: #f4f4f5; border-bottom: 2px solid #a1a1aa;",
};

const darkModeHighlightColorMap = {
  amber:
    "background-color: rgba(250, 204, 21, 0.4); border-bottom: 2px solid #f59e0b; back",
  emerald:
    "background-color: rgba(6, 95, 70, 0.5); border-bottom: 2px solid #10b981; color: white;",
  rose: "background-color: rgba(159, 18, 57, 0.4); border-bottom: 2px solid #fb7185;",
  zinc: "background-color: #3f3f46; border-bottom: 2px solid #27272a;",
};

export function $wrapSelectionInMarkNode(
  selection: RangeSelection,
  isBackward: boolean,
  id: string,
  createNode?: (ids: Array<string>) => MarkNode
): void {
  const nodes = selection.getNodes();
  const anchorOffset = selection.anchor.offset;
  const focusOffset = selection.focus.offset;
  const nodesLength = nodes.length;
  const startOffset = isBackward ? focusOffset : anchorOffset;
  const endOffset = isBackward ? anchorOffset : focusOffset;
  let currentNodeParent;
  let lastCreatedMarkNode;

  // We only want wrap adjacent text nodes, line break nodes
  // and inline element nodes. For decorator nodes and block
  // element nodes, we step out of their boundary and start
  // again after, if there are more nodes.
  for (let i = 0; i < nodesLength; i++) {
    const node = nodes[i];
    if (
      $isElementNode(lastCreatedMarkNode) &&
      lastCreatedMarkNode.isParentOf(node)
    ) {
      // If the current node is a child of the last created mark node, there is nothing to do here
      continue;
    }
    const isFirstNode = i === 0;
    const isLastNode = i === nodesLength - 1;
    let targetNode: LexicalNode | null = null;

    if ($isTextNode(node)) {
      // Case 1: The node is a text node and we can split it
      const textContentSize = node.getTextContentSize();
      const startTextOffset = isFirstNode ? startOffset : 0;
      const endTextOffset = isLastNode ? endOffset : textContentSize;
      if (startTextOffset === 0 && endTextOffset === 0) {
        continue;
      }
      const splitNodes = node.splitText(startTextOffset, endTextOffset);
      targetNode =
        splitNodes.length > 1 &&
        (splitNodes.length === 3 ||
          (isFirstNode && !isLastNode) ||
          endTextOffset === textContentSize)
          ? splitNodes[1]
          : splitNodes[0];
    } else if ($isMarkNode(node)) {
      // Case 2: the node is a mark node and we can ignore it as a target,
      // moving on to its children. Note that when we make a mark inside
      // another mark, it may utlimately be unnested by a call to
      // `registerNestedElementResolver<MarkNode>` somewhere else in the
      // codebase.

      continue;
    } else if ($isElementNode(node) && node.isInline()) {
      // Case 3: inline element nodes can be added in their entirety to the new
      // mark
      targetNode = node;
    }

    if (targetNode !== null) {
      // Now that we have a target node for wrapping with a mark, we can run
      // through special cases.
      if (targetNode && targetNode.is(currentNodeParent)) {
        // The current node is a child of the target node to be wrapped, there
        // is nothing to do here.
        continue;
      }
      const parentNode = targetNode.getParent();
      if (parentNode == null || !parentNode.is(currentNodeParent)) {
        // If the parent node is not the current node's parent node, we can
        // clear the last created mark node.
        lastCreatedMarkNode = undefined;
      }

      currentNodeParent = parentNode;

      if (lastCreatedMarkNode === undefined) {
        // If we don't have a created mark node, we can make one
        const createMarkNode = createNode || $createMarkNode;
        lastCreatedMarkNode = createMarkNode([id]);
        targetNode.insertBefore(lastCreatedMarkNode);
      }

      // Add the target node to be wrapped in the latest created mark node
      lastCreatedMarkNode.append(targetNode);
    } else {
      // If we don't have a target node to wrap we can clear our state and
      // continue on with the next node
      currentNodeParent = undefined;
      lastCreatedMarkNode = undefined;
    }
  }
}

export function TopicHighlightPlugin({
  fraseDocument,
}: {
  fraseDocument: FraseDocument;
}) {
  const [editor] = useLexicalComposerContext();
  const docId = fraseDocument.id;
  const { topics: topicsStore } = useTopicsStore();
  const { editor: editorStore } = useEditorStore();
  const { topics } = topicsStore[docId] || { topics: [] };
  const { highlightTopics } = editorStore;
  const isDarkMode = useIsDarkMode();

  const handleHighlightTopics = debounce(() => {
    if (highlightTopics) {
      editor.update(
        () => {
          const currentSelection = $getSelection();
          for (const term of topics) {
            if (term.blacklist) {
              continue;
            }

            const termLower = term.sing_lower.toLowerCase();
            const children = $getRoot().getAllTextNodes();
            for (const child of children) {
              let textContent = child.getTextContent().toLowerCase();
              let occurrences = []; // Array to store start and end indices of all occurrences

              // Step 1: Scan and Record
              let startIndex = textContent.indexOf(termLower);
              while (startIndex !== -1) {
                const endIndex = startIndex + termLower.length;
                occurrences.push({ startIndex, endIndex });

                startIndex = textContent.indexOf(termLower, endIndex);
              }

              // Step 2: Apply Highlights from last to first
              for (let i = occurrences.length - 1; i >= 0; i--) {
                if ($isMarkNode(child.getParent())) {
                  continue;
                }

                const { startIndex, endIndex } = occurrences[i];
                const selection = $createRangeSelection();

                // Assuming `child` is a TextNode, which should be the case since you're working with text content directly.
                if (child instanceof TextNode) {
                  selection.setTextNodeRange(
                    child,
                    startIndex,
                    child,
                    endIndex
                  );
                }

                $wrapSelectionInMarkNode(selection, false, "topic-highlight");
                if (
                  currentSelection &&
                  $isRangeSelection(currentSelection) &&
                  currentSelection.focus.node instanceof MarkNode
                ) {
                  currentSelection?.focus.set(
                    currentSelection.focus.node.getKey(),
                    currentSelection.focus.offset,
                    "text"
                  );
                }
              }
            }
          }

          const markNodes = $nodesOfType(MarkNode);
          for (const markNode of markNodes) {
            markNode.getChildren().forEach((child) => {
              // Check if the child is a TextNode before calling setStyle
              if (child instanceof TextNode) {
                const colorMap = isDarkMode
                  ? darkModeHighlightColorMap
                  : lightModeHighlightColorMap;

                const topic = topics.find(
                  (topic) =>
                    topic.sing_lower.toLowerCase() ===
                    child.getTextContent().toLowerCase()
                );

                if (topic?.bg_color && colorMap[topic?.bg_color]) {
                  child.setStyle(colorMap[topic?.bg_color || "white"]);
                }
              } else {
                // If the child is not a TextNode, we should not attempt to call setStyle
                $unwrapMarkNode(markNode);
              }
            });
          }

          if (
            currentSelection &&
            $isRangeSelection(currentSelection) &&
            currentSelection.focus.node instanceof MarkNode
          ) {
            currentSelection?.focus.set(
              currentSelection.focus.node.getKey(),
              currentSelection.focus.offset,
              "text"
            );
          }
        },
        {
          tag: "history-merge",
        }
      );
    } else {
      editor.update(
        () => {
          const currentSelection = $getSelection();

          const markNodes = $nodesOfType(MarkNode);
          markNodes.forEach((markNode) => {
            if (markNode.getIDs().includes("topic-highlight")) {
              markNode.getChildren().forEach((child) => {
                if (typeof child.setStyle === "function") {
                  child.setStyle("");
                }
              });
              $unwrapMarkNode(markNode);
            }
          });

          if (
            currentSelection &&
            $isRangeSelection(currentSelection) &&
            currentSelection.focus.node instanceof MarkNode
          ) {
            currentSelection?.focus.set(
              currentSelection.focus.node.getKey(),
              currentSelection.focus.offset,
              "text"
            );
          }
        },
        {
          tag: "history-merge",
        }
      );
    }
  }, 200);

  useEffect(() => {
    // TODO: this is running when the optimize view is not visible, potential side effects with AI writer wrapping nodes as Mark nodes
    handleHighlightTopics();
  }, [topics, highlightTopics, isDarkMode]);

  return null;
}
