import { toast } from "@/hooks/useToast";
import { axios } from "@/lib/axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Notification as StoreNotification } from "src/stores/notifications";
import * as XLSX from "xlsx";
import { create } from "zustand";
import { AuthUser, getUser } from "../auth";
import { getSubscription } from "../auth/api/getSubscription";
import { FraseDocument } from "../documents";
import { getDocuments } from "../documents/api/getDocuments";
import { ClusterEntity } from "../documents/api/getUrlBatch";
import { getPlanNickname } from "../subscription/utils/getPlanNickname";
import { tiers } from "../subscription/utils/tiers";
import {
  Cluster,
  Country,
  FilterOperator,
  FilterParam,
  GSCTokenInfo,
  IntervalData,
  PageData,
  Status,
  StatusFilter,
} from "./types";

export interface SeoAnalyticsState {
  docMap: Record<string, FraseDocument>;
  user: AuthUser | null;
  loading: boolean;
  // google search console module
  accessToken: string | null;
  refreshToken: string | null;
  isInit: boolean;
  isInitiating: boolean;
  isGSCEnabled: boolean;
  queries: Record<string, any>;
  statusList: Status[];
  // google questions module
  allPages: PageData[] | null;
  noPages: boolean;
  allQueries: PageData[] | null; // TODO: define type
  allClusters: Cluster[] | null; // TODO: define type
  globalKpis: Record<string, Record<number, number>> | null;
  siteUrl: string | null;
  sites: string[];
  activeView: "pages" | "queries" | "clusters";
  statusFilter: StatusFilter;
  kwFilter: string | null;
  sort: keyof PageData;
  sortType: "ascending" | "descending";
  selectedQueries: string[];
  activePage: string | null;
  activeQuery: string | null;
  activeGeo: Country | null;
  activeKpi: string;
  downloading: boolean;
  domainLimit: number;
  authorizedSites: string[];
  showSelectSiteDialog: boolean;
  showUpgradeToContinueDialog: boolean;

  init: () => Promise<void>;
  populateDocMap: () => Promise<void>;
  // google search console module
  setAccessToken: (token: string | null) => void;
  setRefreshToken: (token: string | null) => void;
  setIsInit: (isInit: boolean) => void;
  setIsGSCEnabled: (enabled: boolean) => void;
  initGscData: () => Promise<void>;
  fetchGSCToken: () => Promise<void>;
  integrateGSC: (
    navigate: ReturnType<typeof useNavigate>,
    addNotification: (notification: Omit<StoreNotification, "id">) => void
  ) => void;
  getGscCountryCode: (country: string) => Promise<string | "error">;
  getSites: () => Promise<any | "error">;
  getMonthlyIntervals: (
    monthCount?: number
  ) => Array<{ start_date: string; end_date: string }>;
  dedupPages: (list: PageData[]) => PageData[];
  sortIntervalData: (
    data: Record<string, PageData[]>,
    type?: "pages" | "queries"
  ) => IntervalData[];
  getPagesForSite: (
    siteUrl: string,
    url: string | null,
    query: string | null,
    country: string,
    monthCount?: number
  ) => Promise<IntervalData[] | "error">;
  getQueriesForSite: (
    siteUrl: string,
    url: string | null,
    query: string | null,
    country: string
  ) => Promise<IntervalData[] | "error">;
  compareMonths: (
    data: IntervalData[],
    type: "pages" | "queries"
  ) => {
    list: PageData[];
    globalKpis: Record<string, Record<number, number>>;
  };
  categorize: (value: any) => any;
  setCountry: (country: Country | null) => Promise<void>;
  setPageFilter: (
    page: string | null,
    pageFilterType?:
      | "contains"
      | "not_contains"
      | "including_regex"
      | "excluding_regex"
  ) => Promise<void>;
  setQueryFilter: (
    query: string | null,
    queryFilterType?:
      | "contains"
      | "not_contains"
      | "including_regex"
      | "excluding_regex"
  ) => Promise<void>;
  mapToDoc: (pages: PageData[]) => PageData[] | null;
  setShowSelectSiteDialog: (show: boolean) => void;
  setShowUpgradeToContinueDialog: (show: boolean) => void;

  // google questions module
  setAllPages: (pages: PageData[]) => void;
  setAllQueries: (queries: PageData[]) => void;
  setAllClusters: (clusters: any[]) => void; // TODO: define type
  setGlobalKpis: (kpis: Record<string, Record<number, number>>) => void;
  setSiteUrl: (url: string) => void;
  setActiveKpi: (kpi: string) => void;
  setActiveView: (view: "pages" | "queries" | "clusters") => void;
  setStatusFilter: (filter: string) => void;
  setKwFilter: (filter: string | null) => void;
  setSort: (sort: keyof PageData) => void;
  setSortType: (type: "ascending" | "descending") => void;
  toggleQuerySelection: (query: PageData) => void;
  getAllPages: () => Promise<void>;
  getSiteQueries: () => Promise<void>;
  getClusters: () => Promise<void>;
  getTopicClusters: (queries: PageData[]) => Promise<Cluster[]>;
  disableSearchConsole: () => Promise<void>;
  download: () => Promise<void>;
  setAuthorizedSite: (site: string) => Promise<void>;
}

export const useSeoAnalyticsStore = create<SeoAnalyticsState>((set, get) => ({
  user: null,
  docMap: {},
  accessToken: null,
  refreshToken: null,
  isInit: false,
  isInitiating: false,
  isGSCEnabled: false,
  queries: {},
  sites: [],
  activeKpi: "clicks",
  downloading: false,
  domainLimit: 0,
  showSelectSiteDialog: false,
  showUpgradeToContinueDialog: false,
  statusList: [
    { name: "all", desc: "All", color: "#64748b" }, // slate-500
    {
      name: "top result",
      desc: "Position is 1-10 and has not recently experienced ranking loss",
      color: "#bbf7d0", // green-200
    },
    {
      name: "quick win",
      desc: "Achieved 1-10 position within 3 months from publishing",
      color: "#bae6fd", // sky-200
    },
    {
      name: "ranked",
      desc: "Generating clicks, but no leading avg. position",
      color: "#e4e4e7", // zinc-300
    },
    {
      name: "decay",
      desc: "Lost more than 2 positions and clicks decreased",
      color: "#fecaca", // red-200
    },
    {
      name: "opportunity",
      desc: "Position is 10-50, but impressions are growing fast",
      color: "#fef08a", // yellow-200
    },
    {
      name: "unranked",
      desc: "No clicks generated, and weak position",
      color: "#e0e7ff", // indigo-100
    },
  ],
  noPages: false,
  allPages: null,
  allQueries: null,
  allClusters: null,
  globalKpis: null,
  siteUrl: null,
  activeView: "pages",
  statusFilter: "all",
  kwFilter: null,
  sort: "clicks",
  sortType: "descending",
  selectedQueries: [],

  setActiveKpi: (kpi: string) => set({ activeKpi: kpi }),
  setAccessToken: (token) => set({ accessToken: token }),
  setRefreshToken: (token) => set({ refreshToken: token }),
  setIsInit: (isInit) => set({ isInit }),
  setIsGSCEnabled: (enabled) => set({ isGSCEnabled: enabled }),
  setShowSelectSiteDialog: (show) => set({ showSelectSiteDialog: show }),
  setShowUpgradeToContinueDialog: (show) =>
    set({ showUpgradeToContinueDialog: show }),

  init: async () => {
    const { isInitiating } = get();
    if (isInitiating) {
      return;
    }
    set({ isInitiating: true });
    const userSubscriptionData = await getSubscription();
    const userPlanName = getPlanNickname(
      userSubscriptionData?.plan ||
        userSubscriptionData?.subscriptionItems?.data[0].plan.name ||
        "freeTrial"
    );
    const tier = tiers.find((t) => t.name === userPlanName);
    const domainLimit = tier?.gscDomainLimit || 0;
    set({ domainLimit });
    if (domainLimit === 0) {
      set({ isInit: true });
      return;
    }

    const { isGSCEnabled, accessToken, refreshToken, authorizedSites } =
      await fetchGSCToken();
    set({ isGSCEnabled, accessToken, refreshToken, authorizedSites });
    if (!isGSCEnabled) {
      set({ isInit: true });
      return;
    }
    const { initGscData } = get();
    await initGscData();
    set({ isInit: true, isInitiating: false });
  },
  initGscData: async () => {
    const {
      getSites,
      getAllPages,
      populateDocMap,
      domainLimit,
      authorizedSites,
    } = get();
    const [user, sites] = await Promise.all([getUser(), getSites()]);
    set({ user, sites });
    if (domainLimit === 1) {
      if (authorizedSites.length > 0) {
        const authorizedSite = sites.find((site: string) =>
          authorizedSites.includes(site)
        );
        if (authorizedSite) {
          set({ siteUrl: authorizedSite, loading: true });
          await Promise.all([getAllPages(), populateDocMap()]);
          set({ loading: false });
        } else {
          set({ showUpgradeToContinueDialog: true });
        }
      } else {
        set({ showSelectSiteDialog: true });
      }
    } else if (sites.length) {
      // Existing logic for when there are sites
      set({ siteUrl: sites[0], loading: true });
      await Promise.all([getAllPages(), populateDocMap()]);
      set({ loading: false });
    } else {
      // Existing logic for when there are no sites
      toast({
        title: "No sites found",
        description:
          "Please add a site to your Google Search Console to continue",
      });
    }
  },
  integrateGSC: async (
    navigate: ReturnType<typeof useNavigate>,
    addNotification: (notification: Omit<StoreNotification, "id">) => void
  ) => {
    try {
      const response: { auth_url: string } = await axios.post(
        "/searchConsole/getAuthUrl"
      );
      const authUrl = new URL(response.auth_url);
      const currentUrl = new URL(window.location.href);
      const baseUrl = `${currentUrl.protocol}//${currentUrl.host}`;
      authUrl.searchParams.set("redirect_uri", baseUrl);
      const state = { type: "gsc" };
      authUrl.searchParams.set(
        "state",
        encodeURIComponent(JSON.stringify(state))
      );

      // Calculate the position to center the popup
      const width = 800;
      const height = 800;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;

      // Open a popup window
      const popup = window.open(
        authUrl.toString(),
        "authPopup",
        `width=${width},height=${height},top=${top},left=${left}`
      );

      // Check if the popup was blocked
      if (!popup || popup.closed || typeof popup.closed === "undefined") {
        addNotification({
          type: "error",
          title: "Google Authentication Popup Blocked",
          message: "Please allow popups for this site and try again.",
        });
        return;
      }

      // Listen for messages from the popup
      const messageListener = async (event: MessageEvent) => {
        if (event.origin === window.location.origin && event.data.code) {
          const code = event.data.code;
          const state = event.data.state;
          if (state) {
            try {
              const stateObj = JSON.parse(decodeURIComponent(state));
              if (stateObj.type === "gsc") {
                const tokenUrl: { auth_url: string } = await axios.post(
                  "/searchConsole/getTokenUrl",
                  { code }
                );
                if (tokenUrl.auth_url) {
                  const authUrl = new URL(tokenUrl.auth_url);
                  authUrl.searchParams.set("redirect_uri", baseUrl);
                  const authResponse = await axios.post(authUrl.toString());
                  if (authResponse.access_token) {
                    const { gsc_tracked_domains: authorizedSites } =
                      await axios.post("/searchConsole/saveRefreshToken", {
                        refresh_token: authResponse.refresh_token,
                      });
                    // Update the state to reflect GSC integration
                    set({
                      isInit: false,
                      isGSCEnabled: true,
                      accessToken: authResponse.access_token,
                      refreshToken: authResponse.refresh_token,
                      authorizedSites: authorizedSites || [],
                    });

                    const { initGscData } = get();
                    await initGscData();
                    set({ isInit: true });

                    navigate("/app/seo-analytics");
                    return;
                  }
                }
              }
            } catch (error) {
              console.error(error);
            }
          }
          window.removeEventListener("message", messageListener);
        }
      };

      window.addEventListener("message", messageListener);

      // Fallback for Safari: Check if the popup was blocked
      const popupCheckInterval = setInterval(() => {
        if (popup && popup.closed) {
          clearInterval(popupCheckInterval);
          window.removeEventListener("message", messageListener);
        }
      }, 1000);

      // Inject script into the popup
      const safariCheckInterval = setInterval(() => {
        try {
          if (popup && popup.location.href.includes("code")) {
            const params = new URLSearchParams(popup.location.search);
            const code = params.get("code");
            const state = params.get("state");
            if (code && state) {
              clearInterval(safariCheckInterval);
              popup.close();
              // Send the authorization code and state to the main window
              window.postMessage({ code, state }, window.location.origin);
            }
          }
        } catch (error) {
          // Ignore cross-origin errors
        }
      }, 1000);

      // Inject script into the popup
      popup.onload = () => {
        popup.document.body.insertAdjacentHTML(
          "beforeend",
          `<script>
            if (window.opener) {
              const params = new URLSearchParams(window.location.search);
              const code = params.get("code");
              const state = params.get("state");
              if (code && state) {
                window.opener.postMessage({ code, state }, window.location.origin);
                window.close();
              }
            }
          </script>`
        );
      };
    } catch (error) {
      console.error(error);
    }
  },
  // @ts-ignore
  getGscCountryCode: async (country: string) => {
    try {
      const response = await axios.post("/searchConsole/getGscCountryCodes", {
        country,
      });
      return response;
    } catch (error) {
      console.error("Error getting GSC country code:", error);
      return "error";
    }
  },
  populateDocMap: async () => {
    const { user, allPages, allQueries, mapToDoc } = get();
    if (!user) {
      return;
    }
    // @ts-ignore
    const { documents } = await getDocuments({ org_id: user.orgId });
    const docMap: Record<string, FraseDocument> = {};
    documents.forEach((doc) => {
      docMap[doc.query] = doc;
      if (doc.query) {
        doc.query.split(",").forEach((query) => {
          docMap[query] = doc;
        });
      }
      if (doc.metadata.url) {
        docMap[cleanUrl(doc.metadata.url)] = doc;
      }
    });
    set({ docMap });

    if (allPages) {
      const remappedPages = mapToDoc(allPages);
      set({ allPages: remappedPages });
    }
    if (allQueries) {
      const remappedQueries = mapToDoc(allQueries);
      set({ allQueries: remappedQueries });
    }
  },
  setCountry: async (country: Country | null) => {
    const { getAllPages } = get();
    if (country) {
      const response = await axios.post("/searchConsole/getGscCountryCodes", {
        country: country.label,
      });
      country.gscCode = Object.values(response)[0];
    }
    set({ loading: true, activeGeo: country });
    await getAllPages();
    set({ loading: false });
  },

  getSites: async () => {
    const { accessToken } = get();
    try {
      const response: { siteUrl: string }[] = await axios.post(
        "/searchConsole/getSitesForUser",
        {
          accessToken,
        }
      );
      return response.map(({ siteUrl }) => breakDownURL(siteUrl));
    } catch (error) {
      console.error("Error getting sites:", error);
      return "error";
    }
  },

  getMonthlyIntervals(monthCount?: number) {
    const interval: { start_date: string; end_date: string }[] = [];
    const monthArray =
      monthCount === 1 ? [0] : Array.from({ length: 12 }, (_, i) => i);

    monthArray.forEach((i) => {
      const start = dayjs().subtract(i * 30 + 31, "days");
      const end = start.add(30, "days").subtract(1, "day");
      interval.push({
        start_date: start.format("YYYY-MM-DD"),
        end_date: end.format("YYYY-MM-DD"),
      });
    });

    return interval;
  },

  sortIntervalData(
    data: Record<string, PageData[]>,
    type?: "pages" | "queries"
  ) {
    const { dedupPages } = get();
    const sortedList = Object.entries(data).map(([key, list]) => {
      if (type === "pages") {
        list = dedupPages(list);
      }
      const unixTimestamp = dayjs(key).unix();
      return { unix_timestamp: unixTimestamp, date: key, list };
    });
    return sortedList.sort((a, b) => b.unix_timestamp - a.unix_timestamp);
  },

  dedupPages(list: PageData[]): PageData[] {
    const map: { [url: string]: PageData } = {};

    list.forEach((value) => {
      // Remove hash and query parameters from URL
      const cleanUrl = value.url.split("#")[0].split("?")[0];

      if (!map[cleanUrl]) {
        map[cleanUrl] = { ...value, url: cleanUrl };
      } else {
        if (value.impressions) {
          map[cleanUrl].impressions =
            (map[cleanUrl].impressions || 0) + value.impressions;
        }
        if (value.clicks) {
          map[cleanUrl].clicks = (map[cleanUrl].clicks || 0) + value.clicks;
        }
      }
    });

    return Object.values(map);
  },

  getPagesForSite: async (
    siteUrl: string,
    url: string | null,
    query: string | null,
    country: string,
    monthCount?: number
  ) => {
    const { accessToken, getMonthlyIntervals, sortIntervalData } = get();
    const interval = getMonthlyIntervals(monthCount);
    const params = {
      request: "get_pages_site",
      access_token: accessToken,
      interval,
      site: siteUrl,
      size: "25000",
      filters: getFilters(url, query, country),
    };

    try {
      const response = await axios.post("/searchConsole/getGscData", params);
      // @ts-ignore
      const sortedData = sortIntervalData(response, "pages");
      return sortedData;
    } catch (error) {
      console.error("Error getting pages for site:", error);
      return "error";
    }
  },

  getQueriesForSite: async (
    siteUrl: string,
    url: string | null,
    query: string | null,
    country: string
  ) => {
    const { accessToken, getMonthlyIntervals, sortIntervalData } = get();
    const interval = getMonthlyIntervals();
    const params = {
      request: "get_queries_site",
      access_token: accessToken,
      interval,
      site: siteUrl,
      size: "25000",
      filters: getFilters(url, query, country),
    };
    try {
      const response = await axios.post("/searchConsole/getGscData", params);
      // @ts-ignore
      const sortedData = sortIntervalData(response, "queries");
      return sortedData;
    } catch (error) {
      console.error("Error getting queries for site:", error);
      return "error";
    }
  },

  setPageFilter: async (
    page: string | null,
    pageFilterType:
      | "contains"
      | "not_contains"
      | "including_regex"
      | "excluding_regex" = "contains"
  ) => {
    const { getAllPages } = get();

    let processedPage: string | null = page;

    if (page && page.length > 0) {
      // Check if the page contains commas and is not already a regex
      if (pageFilterType.indexOf("regex") === -1 && page.indexOf(",") !== -1) {
        // Convert comma-separated values to regex OR pattern
        processedPage = page.replace(/, /g, ",").split(",").join("|");
        // Update filter type to regex if it was 'contains' or 'not_contains'
        if (pageFilterType === "contains") {
          pageFilterType = "including_regex";
        } else if (pageFilterType === "not_contains") {
          pageFilterType = "excluding_regex";
        }
      }

      // Prefix the processed page with the appropriate filter type
      switch (pageFilterType) {
        case "contains":
          processedPage = `contains_${processedPage}`;
          break;
        case "not_contains":
          processedPage = `not_contains_${processedPage}`;
          break;
        case "including_regex":
          processedPage = `regex_${processedPage}`;
          break;
        case "excluding_regex":
          processedPage = `not_regex_${processedPage}`;
          break;
      }
    } else {
      // If page is empty, set processedPage to null
      processedPage = null;
    }

    // Update the activePage in the store
    set({ loading: true, activePage: processedPage });
    // Fetch all pages with the new filter
    await getAllPages();
    set({ loading: false });
  },
  setQueryFilter: async (
    query: string | null,
    queryFilterType:
      | "contains"
      | "not_contains"
      | "including_regex"
      | "excluding_regex" = "contains"
  ) => {
    const { getAllPages } = get();

    let processedQuery: string | null = query;

    if (query && query.length > 0) {
      // Check if the query contains commas and is not already a regex
      if (
        queryFilterType.indexOf("regex") === -1 &&
        query.indexOf(",") !== -1
      ) {
        // Convert comma-separated values to regex OR pattern
        processedQuery = query.replace(/, /g, ",").split(",").join("|");
        // Update filter type to regex if it was 'contains' or 'not_contains'
        if (queryFilterType === "contains") {
          queryFilterType = "including_regex";
        } else if (queryFilterType === "not_contains") {
          queryFilterType = "excluding_regex";
        }
      }

      // Prefix the processed query with the appropriate filter type
      switch (queryFilterType) {
        case "contains":
          processedQuery = `contains_${processedQuery}`;
          break;
        case "not_contains":
          processedQuery = `not_contains_${processedQuery}`;
          break;
        case "including_regex":
          processedQuery = `regex_${processedQuery}`;
          break;
        case "excluding_regex":
          processedQuery = `not_regex_${processedQuery}`;
          break;
      }
    } else {
      // If query is empty, set processedQuery to null
      processedQuery = null;
    }

    // Update the activeQuery in the store
    set({ loading: true, activeQuery: processedQuery });
    // Fetch all pages with the new filter
    await getAllPages();
    set({ loading: false });
  },

  categorize(value: any) {
    const { statusList } = get();
    if (
      value.age > 3 &&
      value.clicks > 0 &&
      value.clicks_growth < 1 &&
      value.position_diff > 2
    ) {
      value.status = "decay";
      value.color = statusList[4].color;
      value.status_desc = statusList[4].desc;
    } else if (value.age < 4 && value.clicks > 1 && value.position < 20) {
      value.status = "quick win";
      value.color = statusList[2].color;
      value.status_desc = statusList[2].desc;
    } else if (value.age > 3 && value.position < 10) {
      value.status = "top result";
      value.color = statusList[1].color;
      value.status_desc = statusList[1].desc;
    } else if (
      value.position > 10 &&
      value.position < 51 &&
      value.impressions_growth > 1.9
    ) {
      value.status = "opportunity";
      value.color = statusList[5].color;
      value.status_desc = statusList[5].desc;
    } else if (value.clicks > 0) {
      value.status = "ranked";
      value.color = statusList[3].color;
      value.status_desc = statusList[3].desc;
    } else {
      value.status = "unranked";
      value.color = statusList[6].color;
      value.status_desc = statusList[6].desc;
    }
    if (value.clicks > 0 && value.position < 20) {
      value.ranked = true;
    }
    return value;
  },

  compareMonths(values: IntervalData[], type: "pages" | "queries") {
    const { categorize } = get();
    const map: Record<string, any> = {};
    const globalKpis: Record<string, Record<number, number>> = {
      impressions: {},
      clicks: {},
      clicks_growth: {},
      position: {},
      ctr: {},
      ranked_pages: {},
      rank_rate: {},
    };

    values.forEach((value, time_index) => {
      value.list.forEach((item: any) => {
        const key = type === "pages" ? item.url : item.query;

        if (!map[key]) {
          map[key] = {
            current: null,
            previous: null,
            age: 0,
          };
        }

        item.clicks = item.clicks || 0;
        item.ctr = item.ctr || 0;
        item.impressions = item.impressions || 0;

        if (time_index === 0) {
          map[key].current = item;
        } else {
          map[key].previous = item;
        }
        map[key].age += 1;

        ["impressions", "clicks", "position", "ctr"].forEach((metric) => {
          if (!globalKpis[metric][time_index]) {
            globalKpis[metric][time_index] = 0;
          }
          globalKpis[metric][time_index] += item[metric];
        });

        if (item.clicks > 0 && item.position < 25) {
          if (!globalKpis.ranked_pages[time_index]) {
            globalKpis.ranked_pages[time_index] = 0;
          }
          globalKpis.ranked_pages[time_index] += 1;
        }
      });
    });

    Object.entries(globalKpis.clicks).forEach(([index, _val]) => {
      const idx = parseInt(index);
      if (idx !== values.length - 1) {
        const current_clicks = globalKpis.clicks[idx];
        const previous_clicks = globalKpis.clicks[idx + 1] || 0;
        if (current_clicks && current_clicks > 0) {
          const clicks_growth =
            (current_clicks - previous_clicks) / current_clicks;
          globalKpis.clicks_growth[idx] = parseFloat(
            (clicks_growth * 100).toFixed(2)
          );
        } else {
          globalKpis.clicks_growth[idx] = 0;
        }
      } else {
        globalKpis.clicks_growth[idx] = 0;
      }
    });

    ["ranked_pages", "position", "ctr"].forEach((metric) => {
      Object.entries(globalKpis[metric]).forEach(([index, val]) => {
        const idx = parseInt(index);
        const denominator = values[idx].list.length;
        if (metric === "ranked_pages") {
          globalKpis.rank_rate[idx] = parseFloat(
            ((val / denominator) * 100).toFixed(2)
          );
        } else {
          globalKpis[metric][idx] = parseFloat((val / denominator).toFixed(2));
        }
        if (metric === "ctr") {
          globalKpis[metric][idx] = parseFloat(
            (globalKpis[metric][idx] * 100).toFixed(2)
          );
        }
      });
    });

    if (Object.keys(map).length > 0) {
      const list = Object.entries(map)
        .map(([key, value]) => {
          let obj: any = type === "pages" ? { url: key } : { query: key };
          const current = value.current;
          const previous = value.previous;

          if (current) {
            if (previous) {
              obj.impressions_diff = parseInt(
                (current.impressions - previous.impressions).toFixed(0)
              );
              obj.impressions_growth = parseInt(
                (current.impressions / previous.impressions).toFixed(0)
              );
              obj.clicks_diff = parseInt(
                (current.clicks - previous.clicks).toFixed(0)
              );

              if (current.clicks === 0 && previous.clicks === 0) {
                obj.clicks_growth = 1;
              } else if (previous.clicks === 0) {
                obj.clicks_growth = 1;
              } else {
                obj.clicks_growth = parseInt(
                  (current.clicks / previous.clicks).toFixed(1)
                );
              }

              obj.position_diff = parseInt(
                (current.position - previous.position).toFixed(0)
              );
              obj.ctr_diff = parseInt((current.ctr - previous.ctr).toFixed(0));
            } else {
              obj.new_query = true;
              obj.clicks_growth = 1;
              obj.impressions_growth = 1;
            }

            obj.impressions = current.impressions;
            obj.clicks = current.clicks;
            obj.ctr = current.ctr * 100;
            obj.position = current.position;
            obj.age = value.age;
            obj = categorize(obj);
            return obj;
          }
        })
        .filter(Boolean);

      return { list, globalKpis };
    } else {
      return { list: [], globalKpis: {} };
    }
  },

  setAllPages: (pages) => set({ allPages: pages }),
  setAllQueries: (queries) => set({ allQueries: queries }),
  setAllClusters: (clusters) => set({ allClusters: clusters }),
  setGlobalKpis: (kpis) => set({ globalKpis: kpis }),
  setSiteUrl: async (url) => {
    const { getAllPages, siteUrl, getClusters } = get();

    if (siteUrl !== url) {
      set({
        loading: true,
        allQueries: null,
        allClusters: null,
        activePage: null,
        activeQuery: null,
        selectedQueries: [],
        statusFilter: "all",
        kwFilter: null,
        sort: "clicks",
        sortType: "descending",
        activeView: "pages",
        siteUrl: url,
      });
      await Promise.all([getAllPages(), getClusters()]);
      set({ loading: false });
    }
  },
  setActiveView: async (view) => {
    if (view === "queries") {
      set({ activeView: view, loading: true });
      const { getSiteQueries } = get();
      await getSiteQueries();
    } else if (view === "clusters") {
      set({ activeView: view, loading: true });
      const { getClusters } = get();
      await getClusters();
    } else {
      set({ activeView: view });
    }
  },
  setStatusFilter: (filter) => set({ statusFilter: filter }),
  setKwFilter: (filter) => set({ kwFilter: filter }),
  setSort: (sort) => set({ sort: sort }),
  setSortType: (type) => set({ sortType: type }),
  toggleQuerySelection: (query) => {
    set((state) => {
      const selectedQueries = new Set(state.selectedQueries);
      if (selectedQueries.has(query.query)) {
        selectedQueries.delete(query.query);
      } else if (selectedQueries.size < 5) {
        selectedQueries.add(query.query);
      } else {
        // Move toast outside of set function
        return state; // Return current state without changes
      }
      return { selectedQueries: Array.from(selectedQueries) };
    });

    // Check size and show toast after state update
    const { selectedQueries } = get();
    if (
      selectedQueries.length === 5 &&
      !selectedQueries.includes(query.query)
    ) {
      toast({
        title: "You can only select up to 5 queries",
      });
    }
  },

  getAllPages: async () => {
    const {
      setStatusFilter,
      setKwFilter,
      siteUrl,
      activePage,
      activeQuery,
      activeGeo,
      getPagesForSite,
      compareMonths,
      sort,
      sortType,
      getSiteQueries,
      mapToDoc,
    } = get();
    setStatusFilter("all");
    setKwFilter(null);

    if (!siteUrl) {
      console.log("No site selected");
      return;
    }

    try {
      const response = await getPagesForSite(
        siteUrl,
        activePage,
        activeQuery,
        activeGeo?.gscCode || ""
      );
      if (response !== "error") {
        const data = compareMonths(response, "pages");
        const { list: allPages, globalKpis } = data;
        if (allPages?.length > 0) {
          const sortedPages = sortByVariable(allPages, true, sort, sortType);
          const mappedPages = mapToDoc(sortedPages);

          set({
            allPages: mappedPages,
            globalKpis,
            noPages: false,
          });
          await getSiteQueries();
        } else {
          set({
            allPages: [],
            noPages: true,
            globalKpis: null,
          });
        }
      } else {
        set({
          allPages: [],
          noPages: true,
          globalKpis: null,
        });
      }
    } catch (error) {
      console.error("Error getting all pages:", error);
      set({
        allPages: [],
        noPages: true,
      });
    }
  },

  getSiteQueries: async () => {
    const {
      siteUrl,
      activePage,
      activeQuery,
      activeGeo,
      getQueriesForSite,
      compareMonths,
      sort,
      sortType,
      mapToDoc,
    } = get();

    if (!siteUrl) {
      console.log("No site selected");
      return;
    }

    set({
      loading: true,
    });

    try {
      const response = await getQueriesForSite(
        siteUrl,
        activePage,
        activeQuery,
        activeGeo?.gscCode || ""
      );
      if (response !== "error") {
        const data = compareMonths(response, "queries");
        const { list: allQueries } = data;
        if (allQueries?.length > 0) {
          const sortedQueries = sortByVariable(
            allQueries,
            true,
            sort,
            sortType
          );
          const mappedQueries = mapToDoc(sortedQueries);

          set({
            allQueries: mappedQueries,
            loading: false,
          });
        } else {
          set({
            allQueries: [],
            loading: false,
          });
        }
      } else {
        set({
          allQueries: [],
          loading: false,
        });
      }
    } catch (error) {
      console.error("Error getting site queries:", error);
      set({
        allQueries: [],
        loading: false,
      });
    }
  },

  getClusters: async () => {
    const {
      getQueriesForSite,
      siteUrl,
      activePage,
      activeQuery,
      activeGeo,
      compareMonths,
      getTopicClusters,
    } = get();

    if (!siteUrl) {
      console.log("No site selected");
      return;
    }

    set({ loading: true });

    const response = await getQueriesForSite(
      siteUrl,
      activePage,
      activeQuery,
      activeGeo?.gscCode || ""
    );
    if (response !== "error") {
      const { list: allQueries } = compareMonths(response, "queries");
      const allClusters = await getTopicClusters(allQueries);
      set({ allClusters, loading: false });
    }
  },

  getTopicClusters: async (queries: PageData[]) => {
    let text = "";
    queries.slice(0, 1000).forEach((query) => {
      text += `${query.query}. `;
    });
    const params = { texts: [text] };
    const entitiesResponse: any[] = await axios.post("/getEntities", params);
    let allClusters = clusterTerms(entitiesResponse[0]);
    allClusters = getDataPerCluster(allClusters, queries);
    set({ sort: "impressions", sortType: "descending" });
    return sortByVariable(allClusters, true, "impressions", "descending");
  },
  mapToDoc: (list: PageData[]) => {
    const { docMap } = get();

    list.forEach((value) => {
      if (value.query && docMap[value.query]) {
        value.fraseDoc = docMap[value.query];
      } else if (value.url && docMap[cleanUrl(value.url)]) {
        value.fraseDoc = docMap[cleanUrl(value.url)];
      }
    });

    return list;
  },
  setAuthorizedSite: async (site: string) => {
    const { refreshToken, setSiteUrl } = get();
    try {
      await axios.post("/setIntegration", {
        refresh_token: refreshToken,
        name: "SearchConsole",
        gsc_tracked_domains: [site],
      });
      set({ authorizedSites: [site] });
      setSiteUrl(site);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to set authorized site.",
        variant: "destructive",
      });
    }
  },
  disableSearchConsole: async () => {
    try {
      await axios.post("/disableIntegration", { name: "SearchConsole" });

      // Update the store to reflect the disabled integration
      set({ isGSCEnabled: false, accessToken: null });

      // Show a success message
      toast({
        title: "Integration Removed",
        description: "Search Console integration was successfully removed.",
      });
      set({ allPages: null, allQueries: null, allClusters: null });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to remove Search Console integration.",
        variant: "destructive",
      });
    }
  },
  download: async () => {
    set({ downloading: true });
    const excelTabs = [
      { sheetid: "Queries", header: false },
      { sheetid: "Pages", header: false },
    ];

    if (get().allQueries === null) {
      await get().getSiteQueries();
    }
    const { allQueries, allPages, siteUrl } = get();
    const queries = allQueries?.map(packageSheetObject) || [];
    const pages = allPages?.map(packageSheetObject) || [];

    const workbook = XLSX.utils.book_new();

    excelTabs.forEach((tab, index) => {
      const ws = XLSX.utils.json_to_sheet(index === 0 ? queries : pages);
      XLSX.utils.book_append_sheet(workbook, ws, tab.sheetid);
    });

    XLSX.writeFile(workbook, `frase_analytics_${siteUrl}.xlsx`);
    set({ downloading: false });
  },
}));

interface SheetObject {
  URL?: string;
  Query?: string;
  Clicks: number;
  Impressions: number;
  CTR: number;
  Position: number;
  "Impressions Diff": number;
  "Clicks Diff": number;
  "CTR Diff": number;
  "Position Diff": number;
  "Months with Data": number;
  Status: string;
  "Status Desc": string;
}

function packageSheetObject(value: PageData): SheetObject {
  // @ts-ignore
  const obj: SheetObject = {};

  if (value.url) {
    obj["URL"] = value.url;
  }
  if (value.query) {
    obj["Query"] = value.query;
  }

  // Basic metrics first
  obj["Clicks"] = value.clicks || 0;
  obj["Impressions"] = value.impressions || 0;
  obj["CTR"] = value.ctr || 0;
  obj["Position"] = value.position || 0;

  // Frase custom fields after
  obj["Impressions Diff"] = value.impressions_diff || 0;
  obj["Clicks Diff"] = value.clicks_diff || 0;
  obj["CTR Diff"] = value.ctr_diff || 0;
  obj["Position Diff"] = value.position_diff || 0;
  obj["Months with Data"] = value.age || 0;
  obj["Status"] = value.status || "";
  obj["Status Desc"] = value.status_desc || "";

  return obj;
}

async function fetchGSCToken(): Promise<GSCTokenInfo> {
  const integrationResponse: { SearchConsole?: boolean } = await axios.post(
    "/getEnabledIntegrations",
    {}
  );
  if (!integrationResponse.SearchConsole) {
    return {
      isGSCEnabled: false,
      accessToken: null,
      authorizedSites: [],
      refreshToken: null,
    };
  }

  const searchConsoleIntegrationResponse = await axios.post(
    "/fetchIntegrationInfo",
    { integration_type: "SearchConsole" }
  );

  try {
    const refreshToken =
      // @ts-ignore
      searchConsoleIntegrationResponse[0]?.metadataAsMap?.refresh_token;

    if (!refreshToken) {
      return {
        isGSCEnabled: false,
        accessToken: null,
        authorizedSites: [],
        refreshToken: null,
      };
    }

    const authorizedSites =
      // @ts-ignore
      searchConsoleIntegrationResponse[0]?.gsc_tracked_domains || [];

    const tokenResponse = await axios.post(
      "/searchConsole/getAccessTokenFromRefreshToken",
      { refresh_token: refreshToken }
    );

    return {
      isGSCEnabled: true,
      // @ts-ignore
      accessToken: tokenResponse.access_token,
      authorizedSites,
      refreshToken,
    };
  } catch (error) {
    console.error("Error fetching GSC token:", error);
    return {
      isGSCEnabled: false,
      accessToken: null,
      authorizedSites: [],
      refreshToken: null,
    };
  }
}

function getFilters(
  url: string | null,
  query: string | null,
  country?: string
): FilterParam[] {
  const params: FilterParam[] = [];

  const addFilter = (dimension: "page" | "query", value: string | null) => {
    if (!value) return;

    let operator: FilterOperator = "equals";
    let expression = value;

    if (value.startsWith("not_contains_")) {
      operator = "not_contains";
      expression = value.split("not_contains_")[1];
    } else if (value.startsWith("contains_")) {
      operator = "contains";
      expression = value.split("contains_")[1];
    } else if (value.startsWith("not_regex_")) {
      operator = "excluding_regex";
      expression = value.split("not_regex_")[1];
    } else if (value.startsWith("regex_")) {
      operator = "including_regex";
      expression = value.split("regex_")[1];
    }

    params.push({ dimension, operator, expression });
  };

  if (url) {
    addFilter("page", url);
  }
  if (query) {
    addFilter("query", query);
  }

  if (country) {
    params.push({
      dimension: "country",
      operator: "equals",
      expression: country,
    });
  }

  return params;
}

export const sortByVariable = (
  list: any[],
  forceDescending: boolean,
  sort: keyof any,
  sortType: "ascending" | "descending"
) => {
  if (forceDescending || sortType === "descending") {
    // @ts-ignore
    return list.sort((a, b) => b[sort] - a[sort]);
  } else {
    // @ts-ignore
    return list.sort((a, b) => a[sort] - b[sort]);
  }
};

export const cleanUrl = (url: string) => {
  url = url.split("?")[0];
  if (url.indexOf("https://") != -1) {
    url = url.split("https://")[1];
  }
  if (url.indexOf("http://") != -1) {
    url = url.split("http://")[1];
  }
  if (url.indexOf("www.") != -1) {
    url = url.split("www.")[1];
  }
  if (url.split("")[url.length - 1] == "/") {
    url = url.slice(0, -1);
  }
  return url;
};

const clusterTerms = (entities: ClusterEntity[]) => {
  const wordMap: Record<string, Record<string, ClusterEntity>> = {};
  entities.forEach((value) => {
    const words = value.sing_lower.split(" ");
    words.forEach((w, i) => {
      const pos = value.pos.split(" ")[i];
      if ((pos == "PROPN" || pos == "NOUN") && w.length > 3) {
        if (!wordMap[w]) {
          wordMap[w] = {};
        }
        if (!wordMap[w][value.sing_lower]) {
          wordMap[w][value.sing_lower] = value;
        }
      }
    });
  });

  let clusters: Cluster[] = [];
  Object.entries(wordMap).forEach(([word, entities]) => {
    if (Object.keys(entities).length > 1) {
      let clusterEnts: ClusterEntity[] = [];
      let clusterEntCount = 0;
      Object.entries(entities).forEach(([_key, value]) => {
        clusterEnts.push(value);
        clusterEntCount += value.count;
      });
      const entityFreq = clusterEntCount / clusterEnts.length;
      clusterEnts = clusterEnts.sort((a, b) => b.count - a.count);
      const label = clusterEnts[0].entity;
      clusters.push({
        cluster_entities: clusterEnts,
        count: clusterEnts.length,
        entity_frequency: entityFreq,
        label,
        label_word: word,
      });
    }
  });
  let dupeStr = "";
  const validClusters: Cluster[] = [];
  const dupeEntMap: Record<string, boolean> = {};
  clusters = clusters.sort((a, b) => b.count - a.count);
  clusters.forEach((cluster) => {
    if (cluster.entity_frequency > 1) {
      const uniqueEnts: ClusterEntity[] = [];
      cluster.cluster_entities.forEach((entity) => {
        if (!dupeEntMap[entity.sing_lower]) {
          dupeStr += `${entity.entity} `;
          dupeEntMap[entity.sing_lower] = true;
          uniqueEnts.push(entity);
        }
      });
      if (uniqueEnts.length > 1) {
        validClusters.push({
          ...cluster,
          cluster_entities: uniqueEnts,
        });
      }
    }
  });
  return validClusters;
};

const getDataPerCluster = (clusters: Cluster[], queries: PageData[]) => {
  const map: Record<string, Cluster> = {};
  clusters.forEach((cluster) => {
    map[cluster.label_word] = cluster;
  });
  let totalClicks = 0;
  let totalImpressions = 0;
  queries.forEach((query) => {
    const words = query.query.split(" ");
    words.forEach((word) => {
      if (map[word]) {
        if (!map[word].clicks) {
          map[word].clicks = 0;
        }
        if (!map[word].impressions) {
          map[word].impressions = 0;
        }
        if (!map[word].position) {
          map[word].position = 0;
        }
        map[word].clicks += query.clicks || 0;
        map[word].impressions += query.impressions || 0;
        map[word].position += query.position || 0;
        if (!map[word].queries) {
          map[word].queries = [];
        }
        map[word].queries.push({ query: query.query });
      }
    });
    totalClicks += query.clicks || 0;
    totalImpressions += query.impressions || 0;
  });
  const list: Cluster[] = [];
  Object.entries(map).forEach(([_key, value]) => {
    if (!value.clicks) {
      value.clicks = 0;
    }
    if (!value.impressions) {
      value.impressions = 0;
    }
    if (value.position && value.position > 0) {
      value.position = value.position / (value.queries?.length || 1);
    }
    list.push(value);
  });
  return list;
};

const breakDownURL = (url: string): string => {
  try {
    // Remove protocol if present
    const cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/, "");
    // Split by first slash and take the first part
    return cleanUrl.split("/")[0] || "";
  } catch {
    return "";
  }
};
