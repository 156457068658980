import { ContentLayout } from "@/components/Layout";
import AnalyticsTabs from "./components/AnalyticsTabs";
import GSCChart from "./components/GscChart";
import HeaderRow from "./components/HeaderRow";
import KpiRow from "./components/KpiRow";

const SeoAnalyticsDashboard = () => {
  return (
    <ContentLayout title="Content Analytics">
      <div className="h-full flex flex-col">
        <HeaderRow />
        <div className="flex-grow">
          <KpiRow />
          <GSCChart />
          <AnalyticsTabs />
        </div>
      </div>
    </ContentLayout>
  );
};

export default SeoAnalyticsDashboard;
