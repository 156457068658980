import {
  Button,
  Command,
  CommandGroup,
  CommandItem,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  Switch,
} from "@/components/Elements";
import { useUpdateDocument } from "@/features/documents/api/updateDocument";
import { useDocumentStore } from "@/stores/document";
import { CaretDownIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import TextareaAutosize from "react-autosize-textarea";
import { useDebounce } from "use-debounce";

export const ToneOfVoiceStep = ({
  tone,
  setTone,
  customTone,
  setCustomTone,
}) => {
  const [open, setOpen] = useState(false);
  const toneOptions = [
    "concise and readable",
    "casual",
    "convincing",
    "funny",
    "appreciative",
    "passionate",
    "assertive",
    "candid",
    "cautionary",
    "compassionate",
    "direct",
    "earnest",
    "enthusiastic",
    "humble",
    "humorous",
    "inspirational",
    "joyful",
    "thoughtful",
    "urgent",
    "worried",
    "awestruck",
    "critical",
    "informative",
    "custom",
  ];
  const [debouncedCustomTone] = useDebounce(customTone, 300);
  const { document: fraseDocument } = useDocumentStore();
  const updateDocumentMutation = useUpdateDocument({
    notifyOnSuccess: false,
    isResolvingConflict: true,
  });

  const handleUpdateDocumentTone = () => {
    const updatedDocument = {
      ...fraseDocument,
      metadata: {
        ...fraseDocument.metadata,
        tone: tone === "custom" ? "custom" : tone,
        custom_tone: tone === "custom" ? customTone : "",
      },
    };

    updateDocumentMutation.mutateAsync(updatedDocument);
  };

  const handleSelectTone = (selectedTone: string) => {
    if (selectedTone === "custom") {
      setTone("custom");
      setCustomTone("");
      setOpen(false);
    } else {
      const formattedTone =
        selectedTone.charAt(0).toUpperCase() + selectedTone.slice(1);
      setTone(formattedTone);
      setCustomTone("");
      setOpen(false);
    }
  };

  useEffect(() => {
    if (tone !== fraseDocument.metadata.tone) {
      handleUpdateDocumentTone();
    }
  }, [tone]);

  useEffect(() => {
    if (debouncedCustomTone !== fraseDocument.metadata.customTone) {
      handleUpdateDocumentTone();
    }
  }, [debouncedCustomTone]);

  return (
    <div className="flex-col items-center justify-between p-4 w-1/2 max-w-[700px] self-center mx-auto">
      <h2 className="text-xl font-semibold text-zinc-800 dark:text-zinc-100">
        Tone of Voice
      </h2>
      <p className="text-sm mt-4 font-normal text-zinc-600 dark:text-zinc-300">
        Specifying a tone of voice helps Frase's AI sound more human and align
        with your brand values. Select a tone from the provided list or create
        your own for a more personalized touch.
      </p>
      <div className="flex flex-col items-start space-y-8 w-full mt-4">
        <div className="flex flex-col items-start space-y-2 w-full">
          <Label className="font-medium text-zinc-500 text-xs mt-4">
            Tone of voice
          </Label>
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="outlineBlur"
                size="sm"
                className="w-full dark:bg-zinc-900 text-left"
                textClassName="text-xs font-normal truncate w-full"
                endIcon={<CaretDownIcon />}
              >
                {tone === "custom"
                  ? "Custom"
                  : tone.charAt(0).toUpperCase() + tone.slice(1)}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="z-[55] w-[250px]">
              <Command className="max-h-80">
                <ScrollArea className="overflow-y-auto">
                  <CommandGroup>
                    {toneOptions.map((option) => (
                      <CommandItem
                        key={option}
                        onSelect={() => handleSelectTone(option)}
                      >
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </ScrollArea>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
        <div className="flex items-center space-x-2">
          <Switch
            size="sm"
            checked={tone === "custom"}
            onCheckedChange={(checked) => {
              if (checked) {
                setTone("custom");
              } else {
                setTone("concise and readable");
              }
            }}
          />
          <Label className="font-normal text-zinc-500 text-xs">
            Use custom tone
          </Label>
        </div>
        {tone === "custom" && (
          <div className="flex flex-col items-start space-y-2 w-full">
            <Label
              htmlFor="custom-tone"
              className="font-medium text-zinc-500 text-xs"
            >
              Custom tone of voice
            </Label>
            <TextareaAutosize
              id="custom-tone"
              autoFocus={true}
              className="resize-none w-full rounded-md bg-white pl-2.5 py-1 text-sm text-zinc-900 ring-1 ring-inset ring-zinc-900/7.5 transition dark:bg-zinc-900 dark:text-white dark:ring-inset disabled:cursor-not-allowed disabled:opacity-50 hover:ring-zinc-300 dark:ring-white/10 dark:hover:ring-white/20 focus:[&:not(:focus-visible)]:outline-none focus:outline-none  border-none !outline-none focus:ring-emerald-600/50 focus:ring-inset"
              style={{ minHeight: "60px" }}
              placeholder="Enter custom tone of voice"
              value={customTone}
              onChange={(e) => {
                setCustomTone(e.target.value);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
