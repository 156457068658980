import { useState } from "react";
import { Button, Logo } from "../Elements";
import FpsView from "./FpsView";

const EnviromentDisplay = () => {
  const env = import.meta.env.VITE_REACT_APP_ENVIRONMENT;

  return (
    <span className="text-xs text-zinc-600 dark:text-zinc-400 font-medium">
      {env[0].toUpperCase() + env.slice(1)}
    </span>
  );
};

const VersionDisplay = () => {
  const version = import.meta.env.VITE_VERCEL_GIT_COMMIT_SHA;

  if (!version) {
    return null;
  }

  return (
    <span className="text-xs text-zinc-600 dark:text-zinc-400 font-medium">
      {version}
    </span>
  );
};

const ClearLocalStorage = () => {
  return (
    <Button
      size="2xs"
      variant="text"
      onClick={() => {
        window.localStorage.clear();
        window.location.reload();
      }}
    >
      Clear Local Storage
    </Button>
  );
};

const toggleMode = () => {
  document.documentElement.classList.toggle("dark");
  window.localStorage.isDarkMode =
    document.documentElement.classList.contains("dark");
};

export const DevToolbar = () => {
  const [visible, setVisible] = useState(true);

  if (!visible) return null;

  return (
    <div className="flex flex-shrink-0 h-8 w-full bg-zinc-50 dark:bg-zinc-900 border-z px-4 items-center z-50 border-t dark:border-t-zinc-800 space-x-4 justify-between">
      <div className="flex space-x-4">
        <Logo size="sm" renderText={false} imgClassName="h-4" />
        <EnviromentDisplay />
        <VersionDisplay />
        <FpsView />
      </div>
      <div className="flex space-x-2">
        <ClearLocalStorage />
        <Button size="2xs" variant="text" onClick={toggleMode}>
          Toggle Dark Mode
        </Button>
        <Button size="2xs" variant="text" onClick={() => setVisible(false)}>
          Hide Toolbar
        </Button>
      </div>
    </div>
  );
};
