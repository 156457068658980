import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Elements";
import { useEffect } from "react";

export const ReportContentExtractionIssueDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  useEffect(() => {
    if (isOpen) {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "6359064",
          formId: "46d65438-662b-4c4e-b96a-c4d4bb1bd9db",
          target: "#extraction-issue-form",
        });
      } else {
        const script = document.createElement("script");
        script.src = "//js.hsforms.net/forms/v2.js";
        document.body.appendChild(script);

        script.addEventListener("load", () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              portalId: "6359064",
              formId: "46d65438-662b-4c4e-b96a-c4d4bb1bd9db",
              target: "#extraction-issue-form",
            });
          }
        });
      }
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader className="pb-0 flex items-center justify-between">
          <DialogTitle>Report content extraction issue</DialogTitle>
          <DialogCloseButton close={onClose} />
        </DialogHeader>
        <DialogDescription className="px-4">
          <p className="text-sm text-zinc-600 dark:text-zinc-400 mb-4">
            We look forward to receiving your feedback as we continue to improve
            content extraction!
          </p>
          <div id="extraction-issue-form"></div>
        </DialogDescription>
        <DialogFooter className="px-4"></DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
