import {
  Button,
  Checkbox,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input,
  NewDocumentButton,
  Spinner,
} from "@/components/Elements";
import { LogoSpinner } from "@/components/Elements/Spinner/LogoSpinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Elements/Table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Elements/Tooltip";
import {
  sortByVariable,
  useSeoAnalyticsStore,
} from "@/features/seo-analytics/store";
import { PageData } from "@/features/seo-analytics/types";
import { TooltipPortal } from "@radix-ui/react-tooltip";
import { ChevronDown, ChevronUp, ExternalLink, Plus } from "lucide-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { TbHelpCircleFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { StatusBadge } from "../../../StatusBadge";

const PAGE_SIZE = 10;

const QueriesTable: React.FC = () => {
  const {
    allQueries,
    statusList,
    statusFilter,
    setStatusFilter,
    sort,
    sortType,
    setSort,
    setSortType,
    kwFilter,
    setKwFilter,
    toggleQuerySelection,
    selectedQueries,
    loading,
    populateDocMap,
    getPagesForSite,
    activeQuery,
    activeGeo,
    siteUrl,
  } = useSeoAnalyticsStore();

  const [currentPage, setCurrentPage] = useState(1);
  const [isDocumentCreating, setIsDocumentCreating] = useState<string | null>(
    null
  );
  const [urlQueryMap, setUrlQueryMap] = useState<Record<string, string>>({});

  useEffect(() => {
    if (!loading) setCurrentPage(1);
  }, [loading]);

  useEffect(() => {
    setCurrentPage(1);
  }, [statusFilter, kwFilter, sort, sortType]);

  const navigate = useNavigate();

  const [queryFilterInput, setQueryFilterInput] = useState("");
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      setKwFilter(queryFilterInput);
    }, 300);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [queryFilterInput]);

  const filteredQueries = useMemo(() => {
    if (!allQueries) return [];

    const filterAndSort = (list: PageData[]) => {
      const filteredList = list.filter((item) => {
        const statusMatch =
          statusFilter === "all" || item.status === statusFilter;
        const kwMatch =
          !kwFilter ||
          (item.query?.toLowerCase().includes(kwFilter.toLowerCase()) ?? false);
        return statusMatch && kwMatch;
      });

      return sortByVariable(filteredList, false, sort, sortType);
    };

    return filterAndSort(allQueries);
  }, [allQueries, statusFilter, kwFilter, sort, sortType]);

  const sortList = (field: keyof PageData) => {
    if (sort === field) {
      setSortType(sortType === "ascending" ? "descending" : "ascending");
    } else {
      setSort(field);
      setSortType("descending");
    }
  };

  const kFormatter = (num: number) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * Number((Math.abs(num) / 1000).toFixed(1)) + "k"
      : Math.sign(num) * Math.abs(num);
  };

  const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getPageCount = (total: number) => Math.ceil(total / PAGE_SIZE);

  const paginatedQueries = filteredQueries.slice(
    (currentPage - 1) * PAGE_SIZE,
    currentPage * PAGE_SIZE
  );

  const handleCreateDocument = useCallback(
    async (e: React.MouseEvent<HTMLElement>, query: string) => {
      e.stopPropagation();
      const target = e.target as HTMLElement;
      const parentSpan = target.closest("span");
      setIsDocumentCreating(query);
      const pages = await getPagesForSite(
        siteUrl || "",
        null,
        query,
        activeGeo?.gscCode || ""
      );
      if (pages !== "error") {
        const { list = [] } = pages[0];
        const urls = list.sort(
          (a, b) => (b.impressions || 0) - (a.impressions || 0)
        );

        setUrlQueryMap((prev) => ({ ...prev, [query]: urls?.[0]?.url }));
      }

      if (parentSpan) {
        parentSpan.click();
      } else {
        console.warn("Parent span not found");
      }
      setIsDocumentCreating(null);
    },
    [siteUrl, activeQuery, activeGeo]
  );

  if (!allQueries) {
    return (
      <div className="relative flex items-center w-full h-[50%] justify-center">
        <LogoSpinner variant="md" loadingText="Loading queries..." />
      </div>
    );
  }

  if (allQueries.length === 0) {
    return (
      <div className="flex items-center justify-center py-16 text-gray-500 dark:text-gray-400">
        No queries found.
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full w-full relative">
      <div className="flex h-fit justify-between mx-4 py-2 space-x-4">
        <div className="flex items-center">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="2xs"
                className="inline-flex items-center justify-between w-auto"
                endIcon={<ChevronDown />}
              >
                <div className="flex items-center">
                  <span>
                    {statusFilter.replace(/\b\w/g, (char) =>
                      char.toUpperCase()
                    )}{" "}
                    {statusFilter === "all" ? " Queries" : ""}
                  </span>
                </div>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              {statusList.map((status) => (
                <DropdownMenuItem
                  key={status.name}
                  onSelect={() => {
                    setStatusFilter(status.name);
                  }}
                >
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <span
                        className="w-3 h-3 rounded-full mr-2"
                        style={{ backgroundColor: status.color }}
                      />
                      <span className="text-zinc-900 dark:text-zinc-100">
                        {status.name.replace(/\b\w/g, (char) =>
                          char.toUpperCase()
                        )}
                      </span>
                    </div>
                    {status.desc && (
                      <TooltipProvider
                        delayDuration={500}
                        skipDelayDuration={0}
                      >
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div className="text-zinc-400 hover:text-zinc-600 dark:text-zinc-500 dark:hover:text-zinc-300">
                              <TbHelpCircleFilled size={16} />
                            </div>
                          </TooltipTrigger>
                          <TooltipPortal>
                            <TooltipContent>
                              <span>{status.desc}</span>
                            </TooltipContent>
                          </TooltipPortal>
                        </Tooltip>
                      </TooltipProvider>
                    )}
                  </div>
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <div className="ml-4">
            <Input
              placeholder="Filter by keyword"
              className="text-2xs"
              value={queryFilterInput}
              onChange={(value) => setQueryFilterInput(value)}
            />
          </div>
          <span className="ml-4 text-xs text-zinc-700 dark:text-zinc-300">
            {kFormatter(filteredQueries?.length || 0)} queries
          </span>
        </div>
        <div className="flex items-center">
          {selectedQueries.length > 0 && (
            <div className="flex items-center space-x-2 px-3 py-1">
              <span className="text-sm text-zinc-700 dark:text-zinc-300">
                {selectedQueries.length} quer
                {selectedQueries.length > 1 ? "ies" : "y"} selected
              </span>
              <NewDocumentButton
                key="selected-queries"
                defaultValues={{
                  queries: selectedQueries.map((q) => ({ value: q })),
                }}
                onDocumentCreated={populateDocMap}
                skipWorkflowSelection
                trigger={
                  <Button variant="primaryBlur" size="2xs">
                    Create Doc
                  </Button>
                }
              />
            </div>
          )}
          {filteredQueries && filteredQueries.length > PAGE_SIZE && (
            <div className="flex items-center">
              <Button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
                variant="outline"
                size="2xs"
                className="text-zinc-700 dark:text-zinc-300"
              >
                Previous
              </Button>
              <span className="mx-4 text-zinc-700 dark:text-zinc-300 text-xs">
                {currentPage} / {getPageCount(filteredQueries.length)}
              </span>
              <Button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage * PAGE_SIZE >= filteredQueries.length}
                variant="outline"
                size="2xs"
                className="text-zinc-700 dark:text-zinc-300"
              >
                Next
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="w-full h-[calc(100vh-570px)] overflow-auto pb-20">
        <Table className="w-full">
          <TableHeader
            className="sticky top-0 z-[9] bg-white py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8
            dark:bg-zinc-900 dark:text-white h-[48px]"
          >
            <TableRow>
              <TableHead
                className="w-[30%]"
                style={{
                  boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                }}
              >
                Query
              </TableHead>
              <TableHead
                className="w-[10%]"
                style={{
                  boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                }}
              >
                <Button
                  variant="textNode"
                  endIcon={
                    sort === "clicks" && sortType === "descending" ? (
                      <ChevronDown />
                    ) : (
                      <ChevronUp />
                    )
                  }
                  onClick={() => sortList("clicks")}
                >
                  Clicks
                </Button>
              </TableHead>
              <TableHead
                className="w-[10%]"
                style={{
                  boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                }}
              >
                <Button
                  variant="textNode"
                  endIcon={
                    sort === "impressions" && sortType === "descending" ? (
                      <ChevronDown />
                    ) : (
                      <ChevronUp />
                    )
                  }
                  onClick={() => sortList("impressions")}
                >
                  Impressions
                </Button>
              </TableHead>
              <TableHead
                className="w-[10%]"
                style={{
                  boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                }}
              >
                <Button
                  variant="textNode"
                  endIcon={
                    sort === "ctr" && sortType === "descending" ? (
                      <ChevronDown />
                    ) : (
                      <ChevronUp />
                    )
                  }
                  onClick={() => sortList("ctr")}
                >
                  CTR
                </Button>
              </TableHead>
              <TableHead
                className="w-[10%]"
                style={{
                  boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                }}
              >
                <Button
                  variant="textNode"
                  endIcon={
                    sort === "position" && sortType === "descending" ? (
                      <ChevronDown />
                    ) : (
                      <ChevronUp />
                    )
                  }
                  onClick={() => sortList("position")}
                >
                  Position
                </Button>
              </TableHead>
              <TableHead
                className="w-[10%]"
                style={{
                  boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                }}
              >
                <Button
                  variant="textNode"
                  className="whitespace-nowrap"
                  endIcon={
                    sort === "clicks_growth" && sortType === "descending" ? (
                      <ChevronDown />
                    ) : (
                      <ChevronUp />
                    )
                  }
                  onClick={() => sortList("clicks_growth")}
                >
                  Clicks Growth
                </Button>
              </TableHead>
              <TableHead
                className="w-[10%]"
                style={{
                  boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                }}
              >
                Status
              </TableHead>
              <TableHead
                className="w-[7%] text-center whitespace-nowrap"
                style={{
                  boxShadow: "inset 0 -1px 0 0 rgba(31, 41, 55, 0.1)",
                }}
              >
                Frase Doc
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedQueries.map((q, index) => {
              return (
                <TableRow key={q.query || index}>
                  <TableCell className="w-[30%] items-center space-x-2">
                    <Checkbox
                      checked={selectedQueries.includes(q.query || "")}
                      onCheckedChange={() => {
                        toggleQuerySelection(q);
                      }}
                      className="mr-2"
                      aria-label={`Select query: ${q.query}`}
                    />
                    <span>{q.query}</span>
                  </TableCell>
                  <TableCell className="w-[10%]">
                    {q.clicks ? numberWithCommas(q.clicks) : "-"}
                  </TableCell>
                  <TableCell className="w-[10%]">
                    {q.impressions ? numberWithCommas(q.impressions) : "-"}
                  </TableCell>
                  <TableCell className="w-[10%]">
                    {q.ctr ? `${q.ctr.toFixed(2)}%` : "-"}
                  </TableCell>
                  <TableCell className="w-[10%]">
                    {q.position ? q.position.toFixed(2) : "-"}
                  </TableCell>
                  <TableCell className="w-[10%]">
                    {q.clicks_growth && q.clicks_growth > 1
                      ? `${q.clicks_growth}x`
                      : "-"}
                  </TableCell>
                  <TableCell className="w-[10%]">
                    <StatusBadge color={q.color} className="whitespace-nowrap">
                      {q.status}
                    </StatusBadge>
                  </TableCell>
                  <TableCell className="w-[7%] text-center">
                    {q.fraseDoc ? (
                      <Button
                        variant="buttonIcon"
                        buttonIcon={<ExternalLink color="#059669" />}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/app/documents/${q.fraseDoc.hash}`);
                        }}
                        tooltipContent="View document"
                      ></Button>
                    ) : (
                      <NewDocumentButton
                        key={q.query}
                        defaultValues={{
                          queries: [{ value: q.query }],
                          importUrl: urlQueryMap[q.query],
                        }}
                        onDocumentCreated={populateDocMap}
                        skipWorkflowSelection
                        trigger={
                          <Button
                            variant="buttonIcon"
                            buttonIcon={
                              isDocumentCreating === q.query ? (
                                <Spinner size="sm" />
                              ) : (
                                <Plus />
                              )
                            }
                            tooltipContent="Create a new document"
                            onClick={(e) => handleCreateDocument(e, q.query)}
                            disabled={Boolean(isDocumentCreating)}
                          ></Button>
                        }
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default QueriesTable;
