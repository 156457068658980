"use client";

import { CaretDownIcon } from "@radix-ui/react-icons";
import { Check } from "lucide-react";
import * as React from "react";
import { useEffect } from "react";
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
} from "../../../components/Elements";
import { countryOptions } from "../../../components/Elements/Dialog/newDocumentOptions";
import { cn } from "../../../utils/style";

export function CountrySelect({ selectedCountry, setSelectedCountry }) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(selectedCountry);

  useEffect(() => {
    if (value !== selectedCountry) {
      setSelectedCountry(value);
    }
  }, [value, selectedCountry]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outlineBlur"
          aria-expanded={open}
          size="sm"
          className={cn("w-full dark:bg-zinc-900 text-left")}
          textClassName="text-xs font-normal truncate w-full"
          role="combobox"
          endIcon={<CaretDownIcon />}
        >
          {value
            ? countryOptions.find((country) => country.value === value)?.label
            : "Select country..."}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="z-[55]">
        <Command className="max-h-80">
          <CommandInput placeholder="Search country..." />
          <ScrollArea className="overflow-y-auto">
            <CommandEmpty>No country found.</CommandEmpty>
            <CommandGroup>
              {countryOptions.map((country) => (
                <CommandItem
                  key={`${country.label}.${country.value}`}
                  value={`${country.label}.${country.value}`}
                  onSelect={(currentValue) => {
                    setValue(
                      currentValue === value
                        ? ""
                        : currentValue.split(".")[1] || ""
                    );
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === country.value ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {country.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
